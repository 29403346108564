import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import './ShareEvent.css';

const ShareEvent = ({ eventId, eventName, isOpen, onClose }) => {
  const [copied, setCopied] = useState(false);
  const dialogRef = useRef(null);
  const eventUrl = `${window.location.origin}/#/event/${eventId}`;

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const handleShare = async (platform) => {
    const shareText = `Check out ${eventName} on Starting Line!`;
    const encodedUrl = encodeURIComponent(eventUrl);
    const encodedText = encodeURIComponent(shareText);

    switch (platform) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`, '_blank');
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodedText}&url=${encodedUrl}`, '_blank');
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://wa.me/?text=${encodedText}%20${encodedUrl}`, '_blank');
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodedText}&body=${encodedUrl}`;
        break;
      default:
        break;
    }
    
    toast.success('Share link opened');
    onClose();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(eventUrl);
      setCopied(true);
      toast.success('Link copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  const handleBackdropClick = (e) => {
    if (e.target === dialogRef.current) {
      onClose();
    }
  };

  return (
    <dialog 
      ref={dialogRef} 
      className="share-dialog" 
      onClose={onClose}
      onClick={handleBackdropClick}
    >
      <div className="share-content">
        <div className="share-header">
          <h2>Share Event</h2>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        <div className="share-url-container">
          <input
            type="text"
            value={eventUrl}
            readOnly
            className="share-url-input"
          />
          <button 
            className={`copy-button ${copied ? 'copied' : ''}`}
            onClick={copyToClipboard}
          >
            <i className={`fas ${copied ? 'fa-check' : 'fa-copy'}`}></i>
            {copied ? 'Copied!' : 'Copy'}
          </button>
        </div>

        <div className="share-options">
          <button 
            className="share-button facebook" 
            onClick={() => handleShare('facebook')}
          >
            <i className="fab fa-facebook"></i>
            Facebook
          </button>
          
          <button 
            className="share-button twitter" 
            onClick={() => handleShare('twitter')}
          >
            <i className="fab fa-twitter"></i>
            Twitter
          </button>
          
          <button 
            className="share-button linkedin" 
            onClick={() => handleShare('linkedin')}
          >
            <i className="fab fa-linkedin"></i>
            LinkedIn
          </button>
          
          <button 
            className="share-button whatsapp" 
            onClick={() => handleShare('whatsapp')}
          >
            <i className="fab fa-whatsapp"></i>
            WhatsApp
          </button>
          
          <button 
            className="share-button email" 
            onClick={() => handleShare('email')}
          >
            <i className="fas fa-envelope"></i>
            Email
          </button>
        </div>

        <div className="share-footer">
          <p className="share-note">
            <i className="fas fa-info-circle"></i>
            Share this event with friends and family
          </p>
        </div>
      </div>
    </dialog>
  );
};

export default ShareEvent; 