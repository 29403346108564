import { motion } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import './NoteItem.css';

const item = {
  hidden: { y: 20, opacity: 0 },
  show: { y: 0, opacity: 1 }
};

const NoteItem = ({ note, isOwner, currentUser, onDelete }) => {
  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  return (
    <motion.div
      className="note-item"
      variants={item}
      whileHover={{ 
        scale: 1.02,
        transition: { duration: 0.2 }
      }}
    >
      <div className="note-header">
        <div className="author-info">
          {note.authorPhoto ? (
            <img 
              src={note.authorPhoto} 
              alt={note.authorName} 
              className="author-photo"
              loading="lazy"
              crossOrigin="anonymous"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
                e.target.nextElementSibling.style.display = 'flex';
              }}
            />
          ) : (
            <div className="anonymous-avatar">
              <i className="fas fa-user"></i>
            </div>
          )}
          <span className="author-name">{note.authorName}</span>
        </div>
        <div className="meta-info">
          <span className="note-date" title={note.createdAt}>
            {formatTimeAgo(note.createdAt)}
          </span>
          {(isOwner || note.authorId === currentUser?.uid) && (
            <button 
              className="delete-note-button"
              onClick={() => onDelete(note.id)}
              aria-label="Delete note"
              title="Delete note"
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          )}
        </div>
      </div>
      <p className="note-content">
        {note.content.replace(/<[^>]*>/g, '')}
      </p>
    </motion.div>
  );
};

export default NoteItem; 