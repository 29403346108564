import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/GuestBanner.css';

const GuestBanner = ({ eventName, runnerName }) => {
  const location = useLocation();
  
  // Create sign-in URL with redirect back to current event
  const signInUrl = '/signin';
  const signInState = {
    from: location.pathname,
    message: `Sign in to cheer on ${runnerName || 'your friend'}!`,
    eventContext: {
      eventName,
      runnerName
    }
  };

  return (
    <div className="guest-banner">
      <div className="guest-message">
        <h3>
          <span className="emoji">🎉</span> Sign in to cheer on {runnerName || 'your friends'}!
        </h3>
        <p>
          Send a congratulatory message and show your support for their racing goals. 
          Track their progress and be their biggest fan! <span className="emoji">💪</span>
        </p>
      </div>
      <div className="guest-actions">
        <Link 
          to={signInUrl}
          className="sign-in-button"
          state={signInState}
        >
          Join the Celebration
        </Link>
      </div>
    </div>
  );
};

export default GuestBanner; 