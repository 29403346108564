// React and Router imports
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Third-party library imports
import { createClient } from '@supabase/supabase-js';
import { getDoc, doc } from 'firebase/firestore';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

// Context imports
import { AuthProvider, useAuth } from './context/AuthContext';
import { SupabaseProvider } from './context/SupabaseContext';

// Configuration imports
import { db } from './firebase/config';
import { SITE_CONSTANTS } from './constants/meta';

// Component imports
import Navigation from './components/Navigation';
import LandingPage from './components/LandingPage';
import EventDetails from './components/EventDetails';
import PhotoUpload from './components/PhotoUpload';
import EventPreview from './components/EventPreview';
import UserEvents from './components/UserEvents';
import AccountSettings from './components/AccountSettings';
import MyArchivedEvents from './components/MyArchivedEvents';
import DigitalProduct from './components/DigitalProduct';
import Footer from './components/Footer';
import AdminPanel from './components/AdminPanel';
import Error404 from './components/Error404';
import EditEvent from './components/EditEvent';
import StartingLinePage from './components/StartingLinePage';
import Ambassadors from './components/Ambassadors';

// Page imports
import SignInPage from './pages/SignInPage';

// Styles
import './App.css';

// Initialize Supabase client
export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// Protected Route wrapper component
const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsAdmin(userSnap.data().role === 'admin');
        }
      }
      setLoading(false);
    };
    
    checkAdminStatus();
  }, [user]);

  if (loading) return null;
  
  return isAdmin ? children : <Navigate to="/" />;
};

function App() {
  const { 
    defaultTitle, 
    defaultDescription, 
    siteUrl, 
    defaultImageUrl,
    siteName
  } = SITE_CONSTANTS;

  return (
    <HelmetProvider>
      <SupabaseProvider>
        <AuthProvider>
          <Helmet>
            <title>{defaultTitle}</title>
            <meta name="description" content={defaultDescription} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="canonical" href={siteUrl} />
            
            {/* Open Graph - Ensure all required properties */}
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={siteName} />
            <meta property="og:title" content={defaultTitle} />
            <meta property="og:description" content={defaultDescription} />
            <meta property="og:url" content={siteUrl} />
            <meta property="og:image" content={defaultImageUrl} />
            <meta property="og:image:secure_url" content={defaultImageUrl} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
          </Helmet>

          <Toaster position="top-center" />
          <Navigation />
          <div className="app-content">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/events/new" element={<EventDetails />} />
              <Route path="/event-details" element={<EventDetails />} />
              <Route path="/photo-upload" element={<PhotoUpload />} />
              <Route path="/event/:eventId/*" element={<EventPreview />} />
              <Route path="/my-events" element={<UserEvents />} />
              <Route path="/account" element={<AccountSettings />} />
              <Route path="/my-events/archived" element={<MyArchivedEvents />} />
              <Route path="/event/:eventId/edit" element={<EditEvent />} />
              <Route path="/event/:eventId/digital-card" element={<DigitalProduct />} />
              <Route path="/starting-line" element={<StartingLinePage />} />
              <Route 
                path="/admin" 
                element={
                  <AdminRoute>
                    <AdminPanel />
                  </AdminRoute>
                } 
              />
              <Route 
                path="/event/:eventId/edit" 
                element={
                  <AdminRoute>
                    <EditEvent />
                  </AdminRoute>
                } 
              />
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/ambassadors" element={<Ambassadors />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </div>
          <Footer />
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthProvider>
      </SupabaseProvider>
    </HelmetProvider>
  );
}

export default App; 