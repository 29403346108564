import React from 'react';
import { Link } from 'react-router-dom';
import './PromoFooter.css';

const PromoFooter = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="promo-footer">
      <div className="promo-content">
        <div className="promo-header">
          <i className="fas fa-camera-retro"></i>
          <h3>Transform Race Moments into Lasting Memories</h3>
        </div>
        
        <div className="promo-description">
          <p>
            Transform your race photos into something special. Whether it's a stunning 
            digital album for yourself or a thoughtful gift for a fellow runner, we'll 
            help you capture the pride of every finish line moment.
          </p>
        </div>

        <div className="promo-actions">
          <Link to="/" className="promo-button primary" onClick={handleClick}>
            <i className="fas fa-photo-film"></i>
            <span>Create Your Memory</span>
          </Link>
          <Link to="/" className="promo-button secondary" onClick={handleClick}>
            <i className="fas fa-gift"></i>
            <span>Explore Gift Options</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PromoFooter; 