import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './StartFlow.css';
import { SPAM_WORDS } from '../../utils/spamWords';
import { toast } from 'react-hot-toast';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';

const StartFlow = ({ templateData, onAuthRequired, isAuthenticated, autoFocusInput = true }) => {
  const [runnerName, setRunnerName] = useState('');
  const navigate = useNavigate();

  const handleRunnerSubmit = async (e) => {
    e.preventDefault();
    
    // Check authentication when creating from template
    if (templateData && !isAuthenticated) {
      onAuthRequired?.();
      return;
    }

    const cleanName = runnerName.trim();
    
    if (cleanName) {
      const containsSpam = SPAM_WORDS.some(word => {
        if (word instanceof RegExp) {
          return word.test(cleanName);
        }
        return cleanName.toLowerCase().includes(word.toLowerCase());
      });

      if (containsSpam) {
        toast.error('Please enter an appropriate name.');
        return;
      }
      
      if (templateData) {
        try {
          // Create new event document
          const eventRef = await addDoc(collection(db, 'events'), {
            ...templateData,
            runnerName: cleanName,
            isTemplate: false,
            createdFromTemplate: templateData.sourceTemplateId,
            status: 'active',
            createdAt: serverTimestamp(),
            createdBy: 'guest',
            userId: null, // Indicates guest creation
            isPublic: true // Guest events are public by default
          });

          navigate(`/event/${eventRef.id}`, { 
            state: { fromCreation: true } 
          });
        } catch (error) {
          console.error('Error creating event:', error);
          toast.error('Failed to create event. Please try again.');
        }
      } else {
        // Original behavior for non-template events
        navigate(`/events/new?runner=${encodeURIComponent(cleanName)}`);
      }
    }
  };

  return (
    <section className="runner-section">
      <div className="runner-form champion-frame">
        <div className="form-header">
          <h2 className="form-title">
            {templateData ? (
              <>
                <span className="title-prefix">Who's Running</span>
                <span className="event-name">{templateData.eventName}?</span>
              </>
            ) : (
              <>
                <span className="title-prefix">Who's Your</span>
                <span className="title-highlight">Champion?</span>
              </>
            )}
          </h2>
          <p className="form-subtitle">
            {templateData 
              ? "Let's personalize this event for your runner"
              : "Enter your runner's name to begin their journey"
            }
          </p>
        </div>

        <form onSubmit={handleRunnerSubmit} className="runner-input-form">
          <div className="form-group">
            <div className="input-wrapper">
              <input
                id="runnerName"
                name="runnerName"
                type="text"
                value={runnerName}
                onChange={(e) => setRunnerName(e.target.value)}
                placeholder="Enter Runner's Name"
                required
                className="runner-input"
                autoFocus={autoFocusInput}
              />
            </div>
            <small className="input-helper">
              This name will be displayed on the event page
            </small>
          </div>

          <button type="submit" className="start-button">
            {templateData ? (
              <>
                <i className="fas fa-flag-checkered"></i>
                Create Event
              </>
            ) : (
              <>
                <i className="fas fa-running"></i>
                Start Journey
              </>
            )}
          </button>
        </form>
      </div>

      {templateData && (
        <div className="startflow-template bg-gray-50 p-6 rounded-lg shadow-md mt-8 max-w-xl mx-auto">
          <div className="startflow-template__header flex items-center justify-center gap-2 mb-4">
            <i className="startflow-template__icon fas fa-copy text-blue-600 text-xl"></i>
            <h3 className="startflow-template__title text-xl font-semibold text-gray-900">Template Details</h3>
          </div>
          <div className="startflow-template__content text-gray-700 space-y-3">
            <p className="startflow-template__event text-center">
              <strong>Event:</strong> {templateData.eventName}
            </p>
            {templateData.location && (
              <p className="startflow-template__location text-center">
                <i className="fas fa-map-marker-alt text-blue-500 mr-2"></i> {templateData.location}
              </p>
            )}
            <div className="startflow-template__info mt-6 flex items-start gap-3 bg-gradient-to-r from-indigo-50 to-purple-50/50 p-4 rounded-lg border border-indigo-100 shadow-sm hover:shadow-md transition-shadow duration-200">
              <i className="startflow-template__info-icon fas fa-magic text-indigo-500 text-lg"></i>
              <div className="startflow-template__info-content space-y-1">
                <p className="startflow-template__info-title text-sm font-medium text-indigo-700">
                  Fully Customizable Template
                </p>
                <p className="startflow-template__info-text text-sm text-indigo-600/80">
                  Start with this template and customize all details after creation to make it your own
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <footer className="startflow-security">
        <div className="startflow-security__content">
          <i className="fas fa-shield-alt startflow-security__icon"></i>
          <p className="startflow-security__message">
            Your information is protected with industry-standard encryption
            <span className="startflow-security__detail">All data is securely stored and transmitted</span>
          </p>
        </div>
      </footer>
    </section>
  );
};

export default StartFlow; 