export const sendPurchaseConfirmationEmail = async (user, eventData) => {
  try {
    const backendHost ='https://entityco.onrender.com';
    
    const response = await fetch(`${backendHost}/api/runner-roll`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventName: eventData.eventName,
        runnerName: eventData.runnerName,
        email: user.email,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to send runner roll email');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error sending runner roll email:', error);
    throw error;
  }
};