import React, { Suspense, useEffect } from 'react';
import Hero from '../Hero';
import LoadingSpinner from '../LoadingSpinner';
import './LandingPage.css';

// Lazy load components that use Firestore
const StartFlow = React.lazy(() => import('../StartFlow'));
const Features = React.lazy(() => import('../Features'));
const FAQ = React.lazy(() => import('../FAQ'));
const QuickDemo = React.lazy(() => import('../QuickDemo'));

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' // Use 'instant' instead of 'smooth' to prevent jarring UX
    });
  }, []);

  return (
    <div className="landing-container">
      <Hero />
      <Suspense fallback={<LoadingSpinner />}>
        <StartFlow autoFocusInput={false} />
        <Features />
        <FAQ />
        <QuickDemo />
      </Suspense>
    </div>
  );
};

export default LandingPage; 