import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FOOTER_LINKS } from '../../constants/links';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="company-info">
            <Link to="/" className="company-brand">
              <img 
                src="/images/logo.webp" 
                alt="Runner Roll" 
                className="company-logo__image" 
                width="36" 
                height="36"
              />
              <span className="company-name">Runner Roll</span>
            </Link>
            <p className="copyright">
              &copy; {currentYear}{' '}
              <a 
                href="https://www.metamotionhq.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="company-link"
              >
                MetaMotion LLC
              </a>
              . All rights reserved.
            </p>
          </div>
        </div>
        <div className="footer-center">
          <div className="footer-links">
            {FOOTER_LINKS.navigation.map(link => (
              <Link 
                key={link.path}
                to={link.path} 
                className="footer-link"
              >
                {link.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            {FOOTER_LINKS.legal.map(link => (
              <Link 
                key={link.path}
                to={link.path} 
                className="footer-link"
              >
                {link.label}
              </Link>
            ))}
          </div>
          <div className="social-links">
            <a 
              href="https://www.instagram.com/runnerroll/" 
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
              aria-label="Follow us on Instagram"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a 
              href="https://www.facebook.com/profile.php?id=61569563409027" 
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
              aria-label="Follow us on Facebook"
            >
              <i className="fab fa-facebook"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 