import React from 'react';
import '../styles/EventInfo.css';

const EventInfo = ({ eventData, runnerName, isArchived }) => (
  <div className={`champion-frame info-frame ${isArchived ? 'archived' : ''}`}>
    <div className="champion-content">
      <div className="event-info">
        <div className="info-grid">
          <InfoItem
            icon="fa-running"
            label="Runner"
            value={runnerName}
            highlight
          />
          
          {eventData?.location && (
            <InfoItem
              icon="fa-map-marker-alt"
              label="Location"
              value={eventData.location}
            />
          )}
          
          {eventData?.distance && (
            <InfoItem
              icon="fa-route"
              label="Distance"
              value={eventData.distance}
            />
          )}
          
          {eventData?.eventDate && (
            <InfoItem
              icon="fa-calendar-alt"
              label="Date"
              value={formatEventDate(eventData.eventDate)}
            />
          )}
          
          {eventData?.eventTime && (
            <InfoItem
              icon="fa-clock"
              label="Start Time"
              value={formatEventTime(eventData.eventTime)}
            />
          )}
          
          {eventData?.eventDate && (
            <InfoItem
              icon="fa-flag-checkered"
              label="Finish Time"
              value={formatFinishTime(eventData)}
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

const InfoItem = ({ icon, label, value, highlight }) => (
  <div className="info-item">
    <i className={`fas ${icon}`}></i>
    <div className="info-details">
      <span className="info-label">{label}</span>
      <span className={`info-value ${highlight ? 'highlight' : ''}`}>{value}</span>
    </div>
  </div>
);

// Helper functions for formatting
const formatEventDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
};

const formatEventTime = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12;
  return `${displayHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const formatFinishTime = (details) => {
  const [year, month, day] = details.eventDate.split('-').map(Number);
  const eventDate = new Date(year, month - 1, day);
  const now = new Date();
  
  if (eventDate > now) {
    return 'Awaiting results';
  }
  
  if (!details.finishTime || !/^\d{1,2}:\d{2}(:\d{2})?$/.test(details.finishTime)) {
    return 'Not recorded';
  }

  const parts = details.finishTime.split(':').map(Number);
  const [first, second, third] = parts;
  
  const hours = parts.length === 3 ? first : 0;
  const minutes = parts.length === 3 ? second : first;
  const seconds = parts.length === 3 ? third : second;
  
  const timeParts = [];
  
  if (hours > 0) timeParts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  if (minutes > 0) timeParts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  if (seconds > 0) timeParts.push(`${seconds} ${seconds === 1 ? 'second' : 'seconds'}`);
  
  return timeParts.join(' ') || '0 seconds';
};

export default EventInfo; 