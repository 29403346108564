import React, { useState, useRef, useEffect } from 'react';
import { TabButtons } from './components/TabButtons';
import { EventsSection } from './components/EventsSection';
import { CatalogSection } from './components/CatalogSection';
import { useEvents } from './hooks/useEvents';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { useAdminStatus } from '../../hooks/useAdminStatus';

const AdminPanel = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const { isAdmin, loading } = useAdminStatus(user?.email);
  const [activeTab, setActiveTab] = useState('events');
  const {
    events,
    stats,
    isLoading,
    error,
    successMessage,
    handleDelete,
    handleEdit,
    handleVisibilityToggle,
    handleShare
  } = useEvents();

  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 10;

  // Calculate pagination values
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = events?.slice(indexOfFirstEvent, indexOfLastEvent);
  const totalPages = Math.ceil((events?.length || 0) / eventsPerPage);

  const deleteDialogRef = useRef();

  useEffect(() => {
    if (deleteConfirm) {
      deleteDialogRef.current?.showModal();
    } else {
      deleteDialogRef.current?.close();
    }
  }, [deleteConfirm]);

  useEffect(() => {
    if (error?.code === 'permission-denied' || error?.message?.includes('Missing or insufficient permissions')) {
      console.error('Authentication required for admin access');
    }
  }, [error]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    
    if (!loading && !isAdmin) {
      navigate('/');
      toast.error('Admin access required');
    }
  }, [user, isAdmin, navigate, loading]);

  if (loading) {
    return <div className="loading">Checking permissions...</div>;
  }

  if (!user || !isAdmin) {
    return <div className="loading">Access denied</div>;
  }

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} />
      
      <div className="content">
        {activeTab === 'events' ? (
          <>
            <EventsSection 
              events={currentEvents}
              stats={stats}
              isLoading={isLoading}
              onDelete={setDeleteConfirm}
              onEdit={handleEdit}
              onToggleVisibility={handleVisibilityToggle}
              onShare={handleShare}
            />
          </>
        ) : (
          <CatalogSection 
            onEdit={handleEdit}
            onDelete={handleDelete}
            onShare={handleShare}
          />
        )}
      </div>

      <div className="pagination-controls">
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
        >
          Previous
        </button>
        <span className="pagination-info">
          Page {currentPage} of {totalPages}
        </span>
        <button 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
        >
          Next
        </button>
      </div>

      {/* Notifications */}
      {successMessage && (
        <div className="success-message-container">
          <div className="success-message">{successMessage}</div>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
      
      {/* Modals */}
      <dialog 
        ref={deleteDialogRef}
        className="confirm-dialog"
      >
        <form method="dialog">
          <h2>Confirm Deletion</h2>
          <p>Are you sure you want to delete this event? This action cannot be undone.</p>
          <div className="dialog-buttons">
            <button 
              type="button"
              className="button-secondary"
              onClick={() => setDeleteConfirm(null)}
            >
              Cancel
            </button>
            <button 
              type="submit"
              className="button-danger"
              onClick={() => {
                handleDelete(deleteConfirm);
                setDeleteConfirm(null);
              }}
            >
              Delete Event
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
};

export default AdminPanel; 