import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Context and Hooks
import { useAuth } from '../../context/AuthContext';
import { useEventPreview } from './hooks/useEventPreview';

// Components
import ActionButtons from './components/ActionButtons';
import Error404 from '../Error404';
import EventActions from '../EventActions';
import EventHeader from './components/EventHeader';
import EventInfo from './components/EventInfo';
import EventPhoto from './components/EventPhoto';
import GuestBanner from './components/GuestBanner';
import Loading from './components/Loading';
import Notes from '../Notes';
import PromoFooter from '../PromoFooter';
import SavePrompt from '../SavePrompt';
import SaveStatus from './components/SaveStatus';
import ShareEvent from '../ShareEvent';
import TemplateBanner from './components/TemplateBanner';

// Utils and Services
import { deleteEvent } from '../../firebase/config';
import { navigateToStartingLine } from './utils/navigationUtils';
import { toast } from 'react-hot-toast';

// Styles
import './styles/ActionButtons.css';
import './styles/EventHeader.css';
import './styles/EventInfo.css';
import './styles/EventPreview.css';
import './styles/EventTimingDisplay.css';
import './styles/GuestBanner.css';
import './styles/SaveStatus.css';
import './styles/TemplateBanner.css';

function EventPreview() {
  const { eventId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  useEffect(() => {
    if (location.state?.fromCreation && !isSaving) {
      setShowSavePrompt(true);
    }
  }, [location.state, isSaving]);

  const handleSaveConfirm = async () => {
    if (isSaving) return;
    
    setIsSaving(true);
    try {
      await handleSaveToAccount();
      setShowSavePrompt(false);
      navigate(location.pathname, { replace: true });
    } catch (error) {
      console.error('Error saving event:', error);
      toast.error('Failed to save event');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveCancel = async () => {
    if (isSaving) return;
    
    setIsSaving(true);
    try {
      await deleteEvent(eventId);
      setShowSavePrompt(false);
      navigate('/my-events');
      toast.success('Event discarded');
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Failed to discard event');
    } finally {
      setIsSaving(false);
    }
  };

  const {
    eventData,
    loading,
    error,
    saveStatus,
    isImageLoading,
    setIsImageLoading,
    handleSaveToAccount,
    handleEventUpdate,
    handleClaimEvent
  } = useEventPreview(eventId, location, user);

  if (loading) {
    return (
      <>
        <Loading />
        <ShareEvent
          eventId={eventId}
          eventName="Loading..."
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
        />
      </>
    );
  }

  if (error === 'Event not found') {
    return (
      <>
        <Error404 />
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="error-container">{error}</div>
      </>
    );
  }

  if (!eventData) {
    return (
      <>
        <div className="error-container">No event data available</div>
        <ShareEvent
          eventId={eventId}
          eventName="No Data"
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(false)}
        />
      </>
    );
  }

  const {
    runnerName,
    imageUrl,
    userId,
    isPublic,
    status
  } = eventData;

  const isEventSaved = userId === user?.uid;
  const isArchived = status === 'archived';

  const handleUseTemplate = () => navigateToStartingLine(navigate, eventData);

  const handleShareClick = () => {
    setIsShareModalOpen(prev => !prev);
  };

  const pageTitle = eventData?.name 
    ? `${eventData.name} by ${runnerName} | Runner Roll`
    : 'Event Preview | Runner Roll';
  
  const pageDescription = eventData?.description 
    ? `${eventData.description.slice(0, 155)}...` 
    : `Check out this event by ${runnerName} on Runner Roll`;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {imageUrl && <meta property="og:image" content={imageUrl} />}
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      </Helmet>

      <div className="event-preview">
        <div className="event-preview-wrapper">
          <div className="event-preview-content">
            {eventData.isTemplate && !isArchived && (
              <div className="banner-container">
                <TemplateBanner onUseTemplate={handleUseTemplate} />
              </div>
            )}

            <EventHeader details={eventData} status={status} />

            <EventPhoto
              imageUrl={imageUrl}
              eventName={eventData?.name}
              isArchived={isArchived}
              isImageLoading={isImageLoading}
              setIsImageLoading={setIsImageLoading}
            />

            <EventInfo
              eventData={eventData}
              runnerName={runnerName}
              isArchived={isArchived}
            />

            <div className="main-content">
              {!user && (
                <div className="guest-banner-wrapper">
                  <GuestBanner
                    eventName={eventData.name}
                    runnerName={eventData.runnerName}
                  />
                </div>
              )}

              {(!isArchived || isEventSaved) && (
                <div className="actions-container">
                  <ActionButtons
                    user={user}
                    isEventSaved={isEventSaved}
                    eventData={eventData}
                    saveStatus={saveStatus}
                    handleSaveToAccount={handleSaveToAccount}
                    handleShare={handleShareClick}
                    navigate={navigate}
                    isArchived={isArchived}
                    onShare={handleShareClick}
                  />

                  {saveStatus && <SaveStatus status={saveStatus} />}
                </div>
              )}
            </div>

            {/* Admin controls - only for event owner */}
            {user && user.uid === userId && (
              <div className="management-section">
                <EventActions
                  eventId={eventId}
                  isPublic={isPublic}
                  userId={userId}
                  currentUserId={user.uid}
                  onUpdate={handleEventUpdate}
                  onClaim={handleClaimEvent}
                  status={status}
                />
              </div>
            )}

            {/* Notes - visible to any authenticated user */}
            {user && !isArchived && (
              <div className="community-section">
                <Notes eventId={eventId} userId={user.uid} />
              </div>
            )}

            <PromoFooter />
          </div>
        </div>
      </div>

      <ShareEvent
        eventId={eventId}
        eventName={eventData?.name || 'Event'}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />

      <SavePrompt 
        isOpen={showSavePrompt}
        onConfirm={handleSaveConfirm}
        onCancel={handleSaveCancel}
      />
    </HelmetProvider>
  );
}

export default React.memo(EventPreview);