import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { toast } from 'react-hot-toast';

export const useEventPreview = (eventId, location, user) => {
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveStatus, setSaveStatus] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Fetch event data
  const fetchEventData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const eventDoc = await getDoc(doc(db, 'events', eventId));
      
      if (!eventDoc.exists()) {
        setError('Event not found');
        return;
      }

      const eventData = eventDoc.data();
      
      // Handle photo fetch separately with fallback
      let imageUrl = null;
      try {
        if (eventData.photoId) {
          // Get image URL from Firestore photos collection
          const photoDoc = await getDoc(doc(db, 'photos', eventData.photoId));
          if (photoDoc.exists()) {
            imageUrl = photoDoc.data().imageUrl;
          }
        }
      } catch (photoError) {
        // Use default if photo fetch fails
        imageUrl = '/images/default-event.webp';
      }

      // Set default image if no photo was found
      if (!imageUrl) {
        imageUrl = '/images/default-event.webp';
      }

      setEventData({
        ...eventData,
        id: eventId,
        imageUrl: imageUrl || '/images/default-event.webp'  // Use fallback if no image
      });
      
    } catch (err) {
      console.error('Error fetching event:', err);
      setError('Failed to load event');
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  // Save event to user's account
  const handleSaveToAccount = async () => {
    if (!user) {
      toast.error('Please sign in to save events');
      return;
    }

    try {
      setSaveStatus('saving');
      
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        userId: user.uid,
        savedAt: new Date().toISOString()
      });

      setSaveStatus('saved');
      toast.success('Event saved to your account!');
      
      // Update local state
      setEventData(prev => ({
        ...prev,
        userId: user.uid
      }));

    } catch (err) {
      console.error('Error saving event:', err);
      setSaveStatus('error');
      toast.error('Failed to save event');
    }
  };

  // Handle event update
  const handleEventUpdate = async (updates) => {
    try {
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, updates);
      
      setEventData(prev => ({
        ...prev,
        ...updates
      }));

      toast.success('Event updated successfully!');
    } catch (err) {
      console.error('Error updating event:', err);
      toast.error('Failed to update event');
    }
  };

  // Claim event
  const handleClaimEvent = async () => {
    if (!user) {
      toast.error('Please sign in to claim this event');
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        userId: user.uid,
        claimedAt: new Date().toISOString()
      });

      setEventData(prev => ({
        ...prev,
        userId: user.uid
      }));

      toast.success('Event claimed successfully!');
    } catch (err) {
      console.error('Error claiming event:', err);
      toast.error('Failed to claim event');
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  return {
    eventData,
    loading,
    error,
    saveStatus,
    isImageLoading,
    setIsImageLoading,
    handleSaveToAccount,
    handleEventUpdate,
    handleClaimEvent
  };
}; 