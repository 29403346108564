import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import './AmbassadorCTA.css';

const AmbassadorCTA = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogRef = useRef(null);
  const [firstName, setFirstName] = useState('');
  const [instagram, setInstagram] = useState('');

  useEffect(() => {
    if (isDialogOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isDialogOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === dialogRef.current) {
      handleCloseDialog();
    }
  };

  const handleButtonClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!email || !email.includes("@")) {
        throw new Error("Please provide a valid email address");
      }

      if (!firstName.trim()) {
        throw new Error("Please provide your first name");
      }

      const mailingLists = "cm49xto9u02zs0lmgesinf1yj";
      const formBody = 
        "mailingLists=" + encodeURIComponent(mailingLists) +
        "&email=" + encodeURIComponent(email) +
        "&firstName=" + encodeURIComponent(firstName) +
        "&instagram=" + encodeURIComponent(instagram);

      fetch("https://app.loops.so/api/newsletter-form/cm3tcruig01752026bid2wnm3", {
        method: "POST",
        body: formBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      handleCloseDialog();
      toast.success('Successfully applied to the ambassador program! We\'ll be in touch soon.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Error subscribing:", error);
      toast.error('Failed to submit application. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="join-ambassador">
      <h2>Grow Your Influence. Shape the Future.</h2>
      <p>We're seeking innovators who transform lives through fitness.</p>
      <button className="cta-button" onClick={handleButtonClick}>Apply Now</button>
      <dialog 
        ref={dialogRef}
        className="dialog champion-frame"
        onClose={handleCloseDialog}
        onClick={handleBackdropClick}
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          margin: 0
        }}
      >
        <div className="form-header">
          <h3 className="form-title">
            <span className="title-prefix">Join Our</span>
            <span className="title-highlight">Ambassador Program</span>
          </h3>
          <p className="form-subtitle">Lead the Future of Fitness →</p>
        </div>
        
        <form className="runner-input-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="input-wrapper">
              <input 
                type="text" 
                className="runner-input"
                placeholder="Your first name" 
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-wrapper">
              <input 
                type="email" 
                className="runner-input"
                placeholder="Your email address" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-wrapper">
              <input 
                type="text" 
                className="runner-input"
                placeholder="Your Instagram handle (optional)" 
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </div>
          </div>
          
          <div className="button-group">
          <button 
              type="button" 
              className="start-button secondary" 
              onClick={handleCloseDialog}
              disabled={isSubmitting}
            >
              <i className="fas fa-times"></i>
              Close
            </button>
            <button 
              type="submit" 
              className="start-button"
              disabled={isSubmitting}
            >
              <i className="fas fa-paper-plane"></i>
              {isSubmitting ? 'Submitting...' : 'Submit Application'}
            </button>
          </div>
        </form>
      </dialog>
    </section>
  );
};

export default AmbassadorCTA; 