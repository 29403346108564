import React, { useEffect } from 'react';
import StartFlow from '../StartFlow';
import './StartingLinePage.css';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase/config';

const StartingLinePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const templateData = location.state?.template;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSignInRedirect = () => {
    navigate('/signin', {
      state: { 
        from: location.pathname,
        message: 'Please sign in to create an event'
      }
    });
  };

  const user = auth.currentUser;

  return (
    <div className="starting-line-page">
      <div className="page-header">
        <h1>Welcome to the Starting Line</h1>
        <p className="subtitle">
          {templateData 
            ? 'Create your event based on template' 
            : 'Begin your journey by entering your champion\'s name'}
        </p>
        {!user && (
          <div className="auth-prompt">
            <Link 
              to="/signin" 
              state={{ 
                from: location.pathname,
                message: 'Please sign in to create an event',
                templateData 
              }}
              className="sign-in-prompt"
            >
              <i className="fas fa-user-circle"></i>
              Sign in to create your event
            </Link>
            <p className="auth-description">
              Join our community to create and share your running events
            </p>
          </div>
        )}
      </div>
      <div className="start-flow-container">
        <StartFlow 
          templateData={templateData} 
          onAuthRequired={handleSignInRedirect}
          isAuthenticated={!!user}
        />
      </div>
    </div>
  );
};

export default StartingLinePage; 