import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase/config';
import './EditEvent.css';
import { collection, setDoc } from 'firebase/firestore';

const DISTANCE_OPTIONS = [
  'Fun Run',
  '5K',
  '10K',
  '10 miles',
  'Half-Marathon',
  'Marathon',
  '50K',
  '50 miles',
  '100K',
  '100 miles',
  '200 miles',
  'Unspecified'
];

const EditEvent = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [eventData, setEventData] = useState({
    runnerName: '',
    eventName: '',
    eventDate: '',
    eventTime: '',
    location: '',
    finishTime: '',
    status: '',
    photoId: '',
    imageUrl: '',
    isTemplate: false
  });
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const data = eventDoc.data();
          
          if (data.eventTimestamp) {
            const eventDate = new Date(data.eventTimestamp);
            
            const localDate = eventDate.toLocaleDateString('en-CA');
            
            const localTime = eventDate.toLocaleTimeString('en-GB', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            });

            data.eventDate = localDate;
            data.eventTime = localTime;
          }
          
          if (data.photoId) {
            const photoDoc = await getDoc(doc(db, 'photos', data.photoId));
            if (photoDoc.exists()) {
              data.imageUrl = photoDoc.data().imageUrl;
            }
          }
          
          setEventData(data);
        } else {
          setError('Event not found');
        }
      } catch (err) {
        setError('Error fetching event');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value
    });
  };

  const convertToTimestamp = (date, time) => {
    if (!date || !time) return null;
    
    const localDateTime = new Date(`${date}T${time}`);
    
    return {
      timestamp: localDateTime.toISOString(),
      timezoneOffset: localDateTime.getTimezoneOffset()
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { timestamp, timezoneOffset } = convertToTimestamp(
        eventData.eventDate,
        eventData.eventTime
      );

      const updateData = {
        ...eventData,
        eventTimestamp: timestamp,
        eventTimezoneOffset: timezoneOffset,
        eventDate: eventData.eventDate,
        eventTime: eventData.eventTime,
        lastUpdated: new Date().toISOString()
      };

      await updateDoc(doc(db, 'events', eventId), updateData);
      navigate(`/event/${eventId}`);
    } catch (err) {
      setError('Error updating event');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    try {
      const storageRef = ref(storage, `event-photos/${eventId}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);
      
      const photoRef = doc(collection(db, 'photos'));
      await setDoc(photoRef, { imageUrl });
      
      const photoId = photoRef.id;
      setEventData(prev => ({ ...prev, photoId, imageUrl }));
      await updateDoc(doc(db, 'events', eventId), { photoId });
    } catch (err) {
      setError('Error uploading photo');
      console.error(err);
    } finally {
      setUploading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) {
      handlePhotoUpload({ target: { files: [file] } });
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  if (loading) return <div className="loading-text">Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="edit-event-container">
      <header className="event-header">
        <h1>
          <span className="runner-name">{eventData.runnerName}'s</span>
          <span className="header-title">Event Settings</span>
        </h1>
        <p className="header-subtitle">Update event details and preferences</p>
      </header>

      <div className="event-form-container">
        <form onSubmit={handleSubmit} className="event-form">
          <div className="form-section photo-section">
            <h2 className="section-title">
              <i className="fas fa-camera"></i>
              Event Photo
            </h2>
            
            <input
              type="file"
              ref={fileInputRef}
              onChange={handlePhotoUpload}
              accept="image/*"
              className="hidden-input"
              aria-label="Choose a photo"
              tabIndex="-1"
            />

            <div 
              className={`upload-area ${isDragging ? 'drag-over' : ''}`}
              onClick={triggerFileInput}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              role="button"
              tabIndex="0"
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  triggerFileInput();
                }
              }}
              aria-label="Click or drag and drop to upload a photo"
            >
              {eventData.imageUrl ? (
                <div className="image-preview">
                  <img 
                    src={eventData.imageUrl} 
                    alt="Event" 
                    className="event-photo"
                  />
                  <div className="preview-overlay">
                    <i className="fas fa-camera"></i>
                    <span>{uploading ? 'Uploading...' : 'Tap to change photo'}</span>
                  </div>
                </div>
              ) : (
                <div className="upload-prompt">
                  <div className="upload-icon">
                    <i className="fas fa-camera"></i>
                  </div>
                  <h3>Add Event Photo</h3>
                  <p className="upload-instructions">
                    Drag and drop a photo here, or click to select
                  </p>
                  <span className="file-limit">
                    <i className="fas fa-info-circle"></i>
                    Maximum size: 5MB
                  </span>
                </div>
              )}
            </div>

            <div className="helper-section">
              <p className="helper-text">
                <i className="fas fa-info-circle"></i>
                You can always update this photo later
              </p>
              <p className="helper-text">
                <i className="fas fa-camera"></i>
                Choose a clear photo that captures the moment
              </p>
            </div>
          </div>

          <div className="form-section">
            <h2 className="section-title">
              <i className="fas fa-user-runner"></i>
              Basic Information
            </h2>
            <div className="form-group">
              <label htmlFor="runnerName">
                <i className="fas fa-user"></i>{' '}
                Runner Name
              </label>
              <input
                type="text"
                id="runnerName"
                name="runnerName"
                value={eventData.runnerName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="eventName">
                <i className="fas fa-trophy"></i>{' '}
                Event Name
              </label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={eventData.eventName}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="eventDate">
                  <i className="fas fa-calendar"></i>{' '}
                  Event Date
                </label>
                <input
                  type="date"
                  id="eventDate"
                  name="eventDate"
                  value={eventData.eventDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="eventTime">
                  <i className="fas fa-clock"></i>{' '}
                  Start Time
                </label>
                <input
                  type="time"
                  id="eventTime"
                  name="eventTime"
                  value={eventData.eventTime}
                  onChange={handleChange}
                  required
                />
                <small className="helper-text">
                  <i className="fas fa-globe"></i>{' '}
                  Times are shown in {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </small>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2 className="section-title">
              <i className="fas fa-cog"></i>{' '}
              Additional Details
            </h2>
            
            <div className="form-group">
              <label htmlFor="location">
                <i className="fas fa-map-marker-alt"></i>{' '}
                Location
                <span className="optional-text">(optional)</span>
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={eventData.location}
                onChange={handleChange}
                placeholder="Central Park, New York"
              />
            </div>

            <div className="form-group">
              <label htmlFor="distance">
                <i className="fas fa-route"></i>{' '}
                Distance
              </label>
              <select
                id="distance"
                name="distance"
                value={eventData.distance || 'Unspecified'}
                onChange={handleChange}
              >
                {DISTANCE_OPTIONS.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="finishTime">
                <i className="fas fa-stopwatch"></i>{' '}
                Finish Time
                <span className="optional-text">(optional)</span>
              </label>
              <input
                type="text"
                id="finishTime"
                name="finishTime"
                value={eventData.finishTime}
                onChange={handleChange}
                placeholder="e.g., 25:30"
              />
            </div>
          </div>

          <div className="form-section">
            <h2 className="section-title">
              <i className="fas fa-sliders-h"></i>{' '}
              Event Settings
            </h2>
            
            <div className="form-group">
              <label htmlFor="status">
                <i className="fas fa-toggle-on"></i>{' '}
                Status
              </label>
              <select
                id="status"
                name="status"
                value={eventData.status}
                onChange={handleChange}
              >
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="archived">Archived</option>
              </select>
            </div>

            <div className="form-group checkbox-group">
              <label className="checkbox-label">
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    name="isTemplate"
                    checked={eventData.isTemplate}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'isTemplate',
                        value: e.target.checked
                      }
                    })}
                  />
                  <span className="checkmark"></span>
                </div>
                <span className="label-text">Save as template</span>
              </label>
              <small className="helper-text">
                <i className="fas fa-info-circle"></i>{' '}
                Templates can be used as a starting point for creating new events
              </small>
            </div>
          </div>

          {error && (
            <div className="error-message">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <div className="button-group">
            <button 
              type="button" 
              className="cancel-button" 
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-times"></i>
              Cancel
            </button>
            <button 
              type="submit" 
              className="save-button"
              disabled={loading}
            >
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i>
                  Saving...
                </>
              ) : (
                <>
                  <i className="fas fa-check"></i>
                  Save Changes
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEvent; 