import React, { useState, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import QuillEmoji from 'quill-emoji';
import { db } from '../../firebase/config';
import { collection, addDoc, query, where, orderBy, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import QuickNotes from '../QuickNotes';
import './Notes.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SPAM_WORDS } from '../../utils/spamWords';
import NotesList from './NotesList';

// Register the emoji module with Quill
const Quill = ReactQuill.Quill;
Quill.register('modules/emoji', QuillEmoji);

const QUILL_MODULES = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link'],
    ['clean']
  ],
  'emoji-toolbar': true,
  'emoji-textarea': false,
  'emoji-shortname': true,
};

const QUILL_FORMATS = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link',
  'emoji'
];

// Define containsSpamWords function
const containsSpamWords = (text) => {
  return SPAM_WORDS.some(word => {
    if (word instanceof RegExp) {
      return word.test(text);
    }
    return text.toLowerCase().includes(word.toLowerCase());
  });
};

const Notes = ({ eventId, isOwner }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const { user } = useAuth();

  // Wrap fetchNotes in useCallback
  const fetchNotes = useCallback(async () => {
    try {
      const notesQuery = query(
        collection(db, 'notes'),
        where('eventId', '==', eventId),
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(notesQuery);
      const notesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setNotes(notesList);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setLoading(false);
    }
  }, [eventId]); // Add eventId as dependency

  // Update useEffect to include fetchNotes
  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]); // Add fetchNotes as dependency

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cleanContent = newNote.trim();
    if (!cleanContent) return;
    
    // Check for spam before saving
    if (containsSpamWords(cleanContent)) {
      toast.error('Your message contains inappropriate content.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    try {
      const noteData = {
        eventId,
        content: cleanContent,
        createdAt: new Date().toISOString(),
        authorName: user ? user.displayName : 'Anonymous',
        authorId: user ? user.uid : null,
        authorPhoto: user ? user.photoURL : null
      };

      await addDoc(collection(db, 'notes'), noteData);
      setNewNote('');
      await fetchNotes();
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        await deleteDoc(doc(db, 'notes', noteId));
        await fetchNotes(); // Reload notes after deletion
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  if (loading) return <div className="notes-loading">Loading notes...</div>;

  return (
    <div className="notes-container">
      <div className="notes-input-section">
        <QuickNotes onSelectMessage={(message) => setNewNote(message)} />

        <form onSubmit={handleSubmit} className="note-form">
          <ReactQuill
            theme="snow"
            modules={QUILL_MODULES}
            formats={QUILL_FORMATS}
            value={newNote}
            onChange={setNewNote}
            placeholder="Write a congratulatory note..."
            className="note-editor"
          />
          <div className="note-form-footer">
            <button 
              type="submit" 
              disabled={!newNote.trim()}
              className="note-submit-button"
            >
              <i className="fas fa-paper-plane"></i>
              Post Note
            </button>
            <div className="character-count">
              {newNote.replace(/<[^>]*>/g, '').length}/280
            </div>
          </div>
        </form>

        {showSuccess && (
          <div className="success-message">
            <i className="fas fa-check-circle"></i>
            Thank you for your congratulations!
          </div>
        )}

        {notes.length > 0 && (
          <NotesList
            notes={notes.filter(note => !containsSpamWords(note.content))}
            isOwner={isOwner}
            currentUser={user}
            onDeleteNote={handleDeleteNote}
          />
        )}
      </div>
    </div>
  );
};

export default Notes; 