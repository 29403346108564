import React, { useState, useEffect } from 'react';

const EventTimingDisplay = ({ details }) => {
  const [countdown, setCountdown] = useState('');
  const [isPast, setIsPast] = useState(false);

  useEffect(() => {
    const calculateCountdown = () => {
      if (!details?.eventDate) {
        return 'Date not set';
      }

      const [year, month, day] = details.eventDate.split('-').map(Number);
      const [hours, minutes] = (details.eventTime || '00:00').split(':').map(Number);
      
      const eventDate = new Date(year, month - 1, day, hours, minutes);
      const now = new Date();
      const diff = eventDate - now;

      if (diff < 0) {
        setIsPast(true);
        return 'Event completed';
      }

      setIsPast(false);
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const remainingMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (days > 0) {
        return `${days} day${days !== 1 ? 's' : ''} to go`;
      } else if (remainingHours > 0) {
        return `${remainingHours} hour${remainingHours !== 1 ? 's' : ''} to go`;
      } else if (remainingMinutes > 0) {
        return `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''} to go`;
      } else {
        return 'Starting soon';
      }
    };

    const initialCountdown = calculateCountdown();
    setCountdown(initialCountdown);

    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 60000);

    return () => clearInterval(timer);
  }, [details]);

  return (
    <div className={`event-timing-display ${isPast ? 'past' : 'upcoming'}`}>
      <div className="timing-icon">
        <i className={`fas ${isPast ? 'fa-flag-checkered' : 'fa-calendar'}`}></i>
      </div>
      <span className="countdown-text">{countdown}</span>
    </div>
  );
};

export default EventTimingDisplay; 