import { motion } from 'framer-motion';
import NoteItem from './NoteItem';
import './NotesList.css';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const NotesList = ({ notes, isOwner, currentUser, onDeleteNote }) => {
  return (
    <div className="notes-section">
      <div className="notes-header">
        <div className="header-content">
          <i className="fas fa-comment-dots header-icon"></i>
          <h4>Congratulatory Messages</h4>
          <span className="notes-count">{notes.length}</span>
        </div>
        <div className="header-divider"></div>
      </div>
      <motion.div 
        className="notes-list"
        variants={container}
        initial="hidden"
        animate="show"
      >
        {notes.map(note => (
          <NoteItem
            key={note.id}
            note={note}
            isOwner={isOwner}
            currentUser={currentUser}
            onDelete={onDeleteNote}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default NotesList; 