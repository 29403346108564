export const SPAM_WORDS = [
  'casino',
  'viagra',
  'forex',
  'crypto',
  'bitcoin',
  'investment',
  'loan',
  'dating',
  'xxx',
  'porn',
  'sex',
  'gambling',
  'bet',
  'lottery',
  'prize',
  'winner',
  'free money',
  'make money',
  'earn fast',
  'quick cash',
  'cheap',
  'discount',
  'offer',
  'click here',
  'subscribe',
  'buy now',
  'limited time',
  'act now',
  'risk-free',
  'guaranteed',
  'no obligation',
  'trial',
  'credit card',
  'debt',
  'mortgage',
  'insurance',
  'claim',
  'refund',
  'congratulations',
  'urgent',
  'important',
  'attention',
  'call now',
  'order now',
  'apply now',
  'instant',
  'bonus',
  'deal',
  'bargain',
  'save big',
  'special promotion',
  'exclusive offer',
  /[f]+[u]+[c]+[k]+/i,          // Matches common 4-letter word and variants
  /[s]+[h]+[i]+[t]+/i,          // Matches common 4-letter word and variants
  /[b]+[i]+[t]+[c]+[h]+/i,      // Matches common 5-letter word and variants
  /[a]+[s]+[s]+[h]+[o]+[l]+[e]+/i, // Matches common 7-letter word and variants
  /[d]+[a]+[m]+[n]+/i,          // Matches common 4-letter word and variants
  /[h]+[e]+[l]+[l]+/i,          // Matches common 4-letter word and variants
  /[(@#$)]+/,                   // Matches symbol substitutions
  /\b[a-z]+[@#$]+[a-z]+\b/i     // Matches words with symbols inserted
]; 