export const navigateToStartingLine = (navigate, eventData) => {
  navigate('/starting-line', {
    state: {
      template: {
        eventName: eventData.eventData.eventName,
        eventDate: eventData.eventData.eventDate,
        eventTime: eventData.eventData.eventTime,
        location: eventData.eventData.location,
        imageUrl: eventData.imageUrl,
        photoId: eventData.photoId,
        isTemplate: true,
        status: 'active',
        sourceTemplateId: eventData.eventId
      }
    }
  });
}; 