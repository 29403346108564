import React from 'react';

export const StatsCards = ({ stats }) => {
  return (
    <div className="stats-cards">
      {Object.entries(stats).map(([key, value]) => (
        <div key={key} className="stat-card">
          <h3>{key}</h3>
          <p>{value}</p>
        </div>
      ))}
    </div>
  );
}; 