import { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/config';

const formatDate = (timestamp) => {
  if (!timestamp) return '';
  
  // Handle Firestore Timestamp
  if (timestamp?.toDate) {
    return timestamp.toDate().toLocaleDateString();
  }
  
  // Handle regular Date object or timestamp
  return new Date(timestamp).toLocaleDateString();
};

export const CatalogSection = ({ onEdit, onDelete, onShare }) => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const q = query(
          collection(db, 'events'),
          where('isTemplate', '==', true)
        );
        
        const querySnapshot = await getDocs(q);
        const templateData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setTemplates(templateData);
      } catch (error) {
        console.error('Error fetching templates:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, []);

  if (isLoading) {
    return <div className="catalog-section">Loading...</div>;
  }

  return (
    <div className="catalog-section">
      <h2 className="section-title">Catalog Management</h2>
      {templates.length === 0 ? (
        <div className="empty-state">
          <p className="empty-title">No catalog events found</p>
          <span className="helper-text">
            <i className="fas fa-info-circle"></i>
            Create an event and mark it as a template to see it here
          </span>
        </div>
      ) : (
        <div className="table-container">
          <table className="events-table">
            <thead>
              <tr>
                <th>Event Name</th>
                <th>Runner</th>
                <th>Location</th>
                <th>Created</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {templates.map((template) => (
                <tr key={template.id}>
                  <td>{template.eventName}</td>
                  <td>{template.runnerName}</td>
                  <td>{template.location}</td>
                  <td>{formatDate(template.createdAt)}</td>
                  <td>
                    <div className="action-buttons">
                      <button 
                        className="action-button edit"
                        onClick={() => onEdit(template.id)}
                        title="Edit template"
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <button 
                        className="action-button share"
                        onClick={() => onShare(template)}
                        title="Share template"
                      >
                        <i className="fas fa-share-alt"></i>
                      </button>
                      <button 
                        className="action-button delete"
                        onClick={() => onDelete(template.id)}
                        title="Delete template"
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}; 