import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../firebase/config';
import { toast } from 'react-toastify';
import './SignInPage.css';

const SignInPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const message = location.state?.message;

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      
      toast.success('Signed in successfully!');
      
      const isFromStartingLine = location.state?.from === '/starting-line';
      const hasTemplateData = !!location.state?.templateData;

      // Handle Starting Line redirects
      if (isFromStartingLine || hasTemplateData) {
        navigate('/starting-line', { 
          state: { 
            templateData: location.state?.templateData 
          },
          replace: true 
        });
      } else {
        // Handle all other redirects
        navigate(location.state?.from || '/my-events', { replace: true });
      }
    } catch (error) {
      console.error('Sign in error:', error);
      toast.error('Failed to sign in. Please try again.');
    }
  };

  return (
    <div className="signin-page">
      <div className="signin-container">
        <h1>Welcome Back</h1>

        {message && (
          <div className="signin-message">
            <i className="fas fa-info-circle"></i>
            <p>{message}</p>
          </div>
        )}

        <div className="signin-options">
          <button
            className="google-signin-button"
            onClick={handleGoogleSignIn}
          >
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google logo"
            />
            <span>Continue with Google</span>
          </button>
        </div>

        <div className="signin-footer">
          <p>
            By continuing, you agree to our{' '}
            <a href="/terms">Terms of Service</a> and{' '}
            <a href="/privacy">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;