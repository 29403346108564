import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { storage, db } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import './PhotoUpload.css';
import toast from 'react-hot-toast';

const PhotoUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  
  const { eventId, runnerName, eventData } = location.state || {};
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }
      
      setSelectedImage(file);
      setError(null);
      
      // Create preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      setError('Please select an image first');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      // Create a reference to the storage location
      const storageRef = ref(storage, `event-photos/${eventId}/${selectedImage.name}`);
      
      // Upload the image
      const snapshot = await uploadBytes(storageRef, selectedImage);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Add photo information to Firestore
      const photoDoc = await addDoc(collection(db, 'photos'), {
        eventId,
        imageUrl: downloadURL,
        fileName: selectedImage.name,
        uploadedAt: new Date().toISOString(),
        runnerName
      });

      // Update the event document with the photo reference
      await updateDoc(doc(db, 'events', eventId), {
        photoId: photoDoc.id,
        status: 'photo-uploaded'
      });

      // Show success toast before navigation
      toast.success('Photo uploaded successfully!');

      // Navigate to next step
      navigate(`/event/${eventId}`, { 
        state: { 
          eventId,
          photoId: photoDoc.id,
          runnerName,
          eventData,
          imageUrl: downloadURL
        } 
      });

    } catch (err) {
      console.error('Error uploading image:', err);
      setError('Failed to upload image. Please try again.');
      toast.error('Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) {
      handleImageSelect({ target: { files: [file] } });
    }
  };

  return (
    <div className="photo-upload-container">
      <header className="photo-header">
        <h1>
          <span className="runner-name">{runnerName}'s</span>
          <span className="header-title">Race Photo</span>
        </h1>
        <p className="header-subtitle">Capture the achievement with a great photo</p>
      </header>

      <div className="upload-section">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageSelect}
          accept="image/*"
          className="hidden-input"
          aria-label="Choose a photo"
          tabIndex="-1"  // Remove from tab order since we're using the div
        />

        <div 
          className={`upload-area ${isDragging ? 'drag-over' : ''}`}
          onClick={triggerFileInput}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          role="button"
          tabIndex="0"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              triggerFileInput();
            }
          }}
          aria-label="Click or drag and drop to upload a photo"
        >
          {previewUrl ? (
            <div className="image-preview">
              <img src={previewUrl} alt="Selected" />
              <div className="preview-overlay">
                <i className="fas fa-camera"></i>
                <span>Tap to change photo</span>
              </div>
            </div>
          ) : (
            <div className="upload-prompt">
              <div className="upload-icon">
                <i className="fas fa-camera"></i>
              </div>
              <h3>Add Race Photo</h3>
              <p className="upload-instructions">
                Drag and drop a photo here, or click to select
              </p>
              <span className="file-limit">
                <i className="fas fa-info-circle"></i>
                Maximum size: 5MB
              </span>
            </div>
          )}
        </div>

        {error && (
          <div className="error-message">
            <i className="fas fa-exclamation-circle"></i>
            {error}
          </div>
        )}

        <div className="button-container">
          {previewUrl && (
            <button 
              className="change-photo-button" 
              onClick={triggerFileInput}
              disabled={isUploading}
            >
              <i className="fas fa-camera"></i>
              Change Photo
            </button>
          )}
          <button
            className="next-button"
            onClick={handleUpload}
            disabled={!selectedImage || isUploading}
          >
            {isUploading ? (
              <>
                <i className="fas fa-spinner fa-spin"></i>
                Uploading...
              </>
            ) : (
              <>
                <i className="fas fa-arrow-right"></i>
                Continue
              </>
            )}
          </button>
        </div>

        <div className="helper-section">
          <p className="helper-text">
            <i className="fas fa-info-circle"></i>
            You can always update this photo later
          </p>
          <p className="helper-text">
            <i className="fas fa-camera"></i>
            Choose a clear photo that captures the moment
          </p>
        </div>
      </div>
    </div>
  );
};

export default PhotoUpload; 