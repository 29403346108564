import React from 'react';

const SaveStatus = ({ status }) => (
  <div className={`save-status ${status.toLowerCase()}`}>
    {status === 'saving' && (
      <>
        <i className="fas fa-spinner fa-spin"></i>
        <span>Saving to your events...</span>
      </>
    )}
    {status === 'saved' && (
      <>
        <i className="fas fa-check"></i>
        <span>Saved to your events!</span>
      </>
    )}
    {status === 'error' && (
      <>
        <i className="fas fa-exclamation-circle"></i>
        <span>Error saving event</span>
      </>
    )}
  </div>
);

export default SaveStatus; 