import React from 'react';
import heroImage from '../../assets/images/hero.webp';
import './Hero.css';

const Hero = () => {
  return (
    <header className="hero">
      <div className="hero-background">
        <img 
          src={heroImage}
          alt="Runner crossing finish line"
          loading="eager"
          fetchpriority="high"
          className="hero-image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            e.target.parentElement.classList.add('hero-background--fallback');
          }}
        />
        <div className="hero-vignette"></div>
      </div>
      
      <div className="hero-content">
        <div className="hero-text-container">
          <h1 className="hero-title">
            <div className="hero-brand">
              <img 
                src="/images/logo.webp" 
                alt="Runner Roll" 
                className="hero-logo__image" 
                width="64" 
                height="64"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.style.display = 'none';
                  e.target.nextElementSibling.style.display = 'flex';
                }}
              />
              <i 
                className="fas fa-running hero-logo__fallback" 
                style={{ display: 'none' }}
                aria-hidden="true"
              ></i>
              <span className="hero-title-main">Runner Roll</span>
            </div>
            <span className="hero-title-accent">Capture the Moment</span>
          </h1>
          
          <p className="hero-tagline">
            <span className="hero-tagline-text">
              <span className="hero-tagline-highlight">Celebrate</span> your runner's achievement
              <br />with a personalized photo gift
            </span>
            <noscript>
              Celebrate your runner's achievement with a personalized photo gift
            </noscript>
          </p>

          <div className="hero-features">
            <div className="hero-feature-item">
              <i className="hero-feature-icon fas fa-camera-retro"></i>
              <span className="hero-feature-text">Custom Photos</span>
            </div>
            <div className="hero-feature-item">
              <i className="hero-feature-icon fas fa-medal"></i>
              <span className="hero-feature-text">Achievement Display</span>
            </div>
            <div className="hero-feature-item">
              <i className="hero-feature-icon fas fa-share-alt"></i>
              <span className="hero-feature-text">Easy Sharing</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero; 