import React from 'react';
import EventTimingDisplay from './EventTimingDisplay';

const EventHeader = ({ details, status }) => (
  <header className="event-header">
    <div className="header-content">
      <div className="title-wrapper">
        <div className="title-section">
          <div className="icon-wrapper">
            <i className="fas fa-trophy trophy-icon"></i>
          </div>
          <h1 className="event-title">{details?.eventName || 'Event'}</h1>
        </div>
      </div>
      
      <div className="status-section">
        {status === 'archived' ? (
          <div className="status-badge archived">
            <i className="fas fa-archive"></i>
            <span>Archived</span>
          </div>
        ) : (
          <div className="status-badge">
            <EventTimingDisplay details={details} />
          </div>
        )}
      </div>
    </div>
  </header>
);

export default EventHeader; 