import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './EventActions.css';

const EventActions = ({
  eventId,
  isPublic,
  userId,
  currentUserId,
  onUpdate,
  onClaim,
  status = 'active'
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [actionInProgress, setActionInProgress] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const dialogRef = useRef();

  useEffect(() => {
    if (showConfirmDialog) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [showConfirmDialog]);

  const handleAction = async (action) => {
    if (loading) return;
    
    if (action === 'archive') {
      setShowConfirmDialog(true);
      return;
    }

    setActionInProgress(action);
    setLoading(true);
    setError(null);
    
    try {
      switch (action) {
        case 'edit':
          navigate(`/event/${eventId}/edit`);
          break;
          
        case 'archive': {
          const isArchived = status === 'archived';
          const newStatus = isArchived ? 'active' : 'archived';
          
          // Confirm action
          const confirmMessage = isArchived
            ? 'Are you sure you want to restore this event?'
            : 'Are you sure you want to archive this event?';

          if (!window.confirm(confirmMessage)) {
            setLoading(false);
            setActionInProgress('');
            return;
          }

          // Update event status
          const eventRef = doc(db, 'events', eventId);
          const updateTimestamp = new Date().toISOString();
          
          const updateData = {
            status: newStatus,
            lastUpdated: updateTimestamp,
            [isArchived ? 'restoredAt' : 'archivedAt']: updateTimestamp
          };

          await updateDoc(eventRef, updateData);

          // Verify update
          const updatedDoc = await getDoc(eventRef);
          if (!updatedDoc.exists()) {
            throw new Error('Event not found after update');
          }

          const updatedData = updatedDoc.data();
          if (updatedData.status !== newStatus) {
            throw new Error('Status update failed');
          }

          // Update UI
          if (onUpdate) {
            onUpdate(updatedData);
          }

          // Show success message
          toast.success(
            isArchived 
              ? 'Event restored successfully'
              : 'Event archived successfully'
          );
          break;
        }
        
        case 'visibility': {
          const eventRef = doc(db, 'events', eventId);
          const newIsPublic = !isPublic;

          await updateDoc(eventRef, { isPublic: newIsPublic });

          // Fetch latest event data
          const updatedEventDoc = await getDoc(eventRef);
          if (updatedEventDoc.exists()) {
            // Call the parent component's update function if provided
            if (onUpdate) {
              onUpdate(updatedEventDoc.data());
            }
          }

          toast.success(`Event is now ${newIsPublic ? 'public' : 'private'}`);
          break;
        }
        case 'digital':
          navigate(`/event/${eventId}/digital-card`);
          break;
        case 'physical':
          // TODO: Implement physical product generation
          console.log('Generate physical product:', eventId);
          break;
        default:
          break;
      }
    } catch (err) {
      console.error('Action error:', err);
      
      const errorMessage = status === 'archived'
        ? 'Failed to restore event. Please try again.'
        : 'Failed to archive event. Please try again.';
      
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setActionInProgress('');
    }
  };

  const handleConfirmArchive = async () => {
    setShowConfirmDialog(false);
    setActionInProgress('archive');
    setLoading(true);
    setError(null);
    
    try {
      const isArchived = status === 'archived';
      const newStatus = isArchived ? 'active' : 'archived';
      
      // Update event status
      const eventRef = doc(db, 'events', eventId);
      const updateTimestamp = new Date().toISOString();
      
      const updateData = {
        status: newStatus,
        lastUpdated: updateTimestamp,
        [isArchived ? 'restoredAt' : 'archivedAt']: updateTimestamp
      };

      await updateDoc(eventRef, updateData);

      // Verify update
      const updatedDoc = await getDoc(eventRef);
      if (!updatedDoc.exists()) {
        throw new Error('Event not found after update');
      }

      const updatedData = updatedDoc.data();
      if (updatedData.status !== newStatus) {
        throw new Error('Status update failed');
      }

      // Update parent component first
      if (onUpdate) {
        // Pass success message to parent to handle toast
        onUpdate(updatedData, {
          success: isArchived 
            ? 'Event restored successfully'
            : 'Event archived successfully'
        });
      } else {
        // Only show toast if parent doesn't handle it
        toast.success(
          isArchived 
            ? 'Event restored successfully'
            : 'Event archived successfully'
        );
      }
    } catch (err) {
      console.error('Action error:', err);
      
      const errorMessage = status === 'archived'
        ? 'Failed to restore event. Please try again.'
        : 'Failed to archive event. Please try again.';
      
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setActionInProgress('');
    }
  };

  return (
    <div className="event-management-controls">
      <div className="management-header">
        <i className="fas fa-shield-alt"></i>
        <span className="management-label">Admin Controls</span>
        <div className="owner-badge">Event Owner</div>
      </div>
      
      <div className="management-buttons">
        <button
          onClick={() => handleAction('archive')}
          disabled={loading}
          className={`action-button ${status === 'archived' ? 'unarchive' : 'archive'}`}
          title={status === 'archived' ? 'Restore' : 'Archive'}
        >
          {actionInProgress === 'archive' ? (
            <i className="fas fa-spinner fa-spin" />
          ) : (
            <i className={`fas fa-${status === 'archived' ? 'box-open' : 'archive'}`} />
          )}
          <span className="button-label">
            {actionInProgress === 'archive'
              ? (status === 'archived' ? 'Restoring...' : 'Archiving...') 
              : (status === 'archived' ? 'Restore' : 'Archive')
            }
          </span>
        </button>

        {userId === currentUserId && status !== 'archived' && (
          <>
            <button
              onClick={() => handleAction('edit')}
              disabled={loading}
              className="action-button edit"
              title="Edit event details"
            >
              <i className={actionInProgress === 'edit' ? 'fas fa-spinner fa-spin' : 'fas fa-edit'} />
              <span className="button-label">Edit</span>
            </button>

            <button
              onClick={() => handleAction('digital')}
              disabled={loading}
              className="action-button digital"
              title="Finalize Digital Card"
            >
              <i className={actionInProgress === 'digital' ? 'fas fa-spinner fa-spin' : 'fas fa-id-card'} />
              <span className="button-label">Digital Card</span>
            </button>
          </>
        )}
      </div>

      {error && (
        <div className="error-message">
          <i className="fas fa-exclamation-circle"></i>
          {error}
        </div>
      )}

      <dialog ref={dialogRef} className="confirm-dialog">
        <form method="dialog">
          <h2>Confirm {status === 'archived' ? 'Restore' : 'Archive'}</h2>
          <p>
            {status === 'archived'
              ? 'Are you sure you want to restore this event?'
              : 'Are you sure you want to archive this event?'
            }
          </p>
          <div className="dialog-buttons">
            <button 
              type="button"
              className="button-secondary"
              onClick={() => setShowConfirmDialog(false)}
            >
              Cancel
            </button>
            <button 
              type="submit"
              className={`button-${status === 'archived' ? 'primary' : 'warning'}`}
              onClick={handleConfirmArchive}
            >
              {status === 'archived' ? 'Restore Event' : 'Archive Event'}
            </button>
          </div>
        </form>
      </dialog>
    </div>
  );
};

export default EventActions; 