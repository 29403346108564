import React from 'react';
import { Img } from 'react-image';
import '../styles/EventPhoto.css';

const EventPhoto = ({ imageUrl, eventName, isArchived, isImageLoading, setIsImageLoading }) => {
  const handleLoad = () => setIsImageLoading(false);
  const handleError = () => setIsImageLoading(false);

  const LoadingPlaceholder = () => (
    <div className="placeholder loading">
      <i className="fas fa-image"></i>
    </div>
  );

  const ErrorPlaceholder = () => (
    <div className="placeholder error">
      <i className="fas fa-exclamation-circle"></i>
      <span>Unable to load image</span>
    </div>
  );

  return (
    <div className={`event-photo ${isArchived ? 'archived' : ''}`}>
      <div className="photo-container">
        <Img
          src={imageUrl}
          alt={eventName || 'Event'}
          loader={<LoadingPlaceholder />}
          unloader={<ErrorPlaceholder />}
          className="photo"
          onLoad={handleLoad}
          onError={handleError}
          decode={true}
          loading="lazy"
        />
        <div className="overlay">
          <span className="tag">
            <i className="fas fa-camera"></i>
            Event Photo
          </span>
        </div>
      </div>
    </div>
  );
};

export default EventPhoto; 