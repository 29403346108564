import React from 'react';
import { useNavigate } from 'react-router-dom';
import './EventsTable.css';

export const EventsTable = ({ events, onDelete, onToggleVisibility }) => {
  const navigate = useNavigate();

  return (
    <table className="events-table">
      <thead>
        <tr>
          <th>Event Date</th>
          <th>Event Name</th>
          <th>Runner Name</th>
          <th>Status</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event) => (
          <tr key={event.id}>
            <td>
              <span className="event-date">
                {(() => {
                  const date = event.eventDate?.split('-').map(Number);
                  const time = (event.eventTime || '00:00').split(':').map(Number);
                  
                  if (!date) return 'Invalid Date';
                  
                  const eventDateTime = new Date(date[0], date[1] - 1, date[2], time[0], time[1]);
                  
                  return eventDateTime.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit'
                  });
                })()}
              </span>
            </td>
            <td>{event.eventName}</td>
            <td>{event.runnerName || 'No Runner'}</td>
            <td>{event.status}</td>
            <td>
              {event.isTemplate && (
                <span className="template-badge" title="Template Event">
                  <i className="fas fa-copy"></i>
                  Template
                </span>
              )}
            </td>
            <td>
              <div className="admin-action-buttons">
                <button
                  className="action-button view"
                  onClick={() => navigate(`/event/${event.id}`)}
                  title="View Event"
                >
                  <i className="fas fa-eye"></i>
                </button>
                <button
                  className="action-button visibility"
                  onClick={() => onToggleVisibility(event.id)}
                  title={event.isPublic ? "Make Private" : "Make Public"}
                >
                  <i className={`fas fa-${event.isPublic ? 'globe' : 'lock'}`}></i>
                </button>
                <button
                  className="action-button delete"
                  onClick={() => onDelete(event.id)}
                  title="Delete Event"
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}; 