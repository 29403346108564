import React, { useState, useCallback, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { toast } from 'react-toastify';
import LoadingSpinner from '../LoadingSpinner';
import CardPhoto from './components/CardPhoto';
import CardInfo from './components/CardInfo';
import './DigitalProduct.css';
import { useSupabase } from '../../context/SupabaseContext';
import { getAuth } from 'firebase/auth';
import { sendPurchaseConfirmationEmail } from '../../services/emailService';

function DigitalProduct() {
  const supabase = useSupabase();
  const { eventId } = useParams();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [selectedFields, setSelectedFields] = useState({
    runnerName: true,
    eventName: true,
    eventDate: true,
    eventTime: true,
    location: true,
    finishTime: true,
    photo: true
  });
  const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(false);

  const fetchEventData = useCallback(async () => {
    try {
      const eventDoc = await getDoc(doc(db, 'events', eventId));
      if (!eventDoc.exists()) {
        setError('Event not found');
        return;
      }

      const eventDetails = eventDoc.data();
      const photoDoc = await getDoc(doc(db, 'photos', eventDetails.photoId));

      setEventData({
        ...eventDetails,
        imageUrl: photoDoc.exists() ? photoDoc.data().imageUrl : null
      });
    } catch (err) {
      console.error('Error fetching event:', err);
      setError('Failed to load event details');
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]);

  const handlePurchase = async () => {
    setIsPurchaseDisabled(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        toast.error('Please sign in to make a purchase', {
          position: "top-center",
          autoClose: 3000,
        });
        return;
      }
      
      // Insert purchase record
      const { error: purchaseError } = await supabase
        .from('digital_card_purchases')
        .insert([
          {
            event_id: eventId,
            event_name: eventData.eventName,
            runner_name: eventData.runnerName,
            purchase_amount: 9.99,
            status: 'completed',
            selected_fields: selectedFields,
            user_id: user.uid
          }
        ])
        .select();

      if (purchaseError) throw purchaseError;

      // Send confirmation email via Loops
      try {
        await sendPurchaseConfirmationEmail(user, eventData);
      } catch (emailError) {
        console.error('Email sending error:', emailError);
        // You might want to log this error to your monitoring system
      }

      toast.success('🎉 Purchase simulated successfully!', {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (err) {
      console.error('Purchase error:', err);
      toast.error('😕 Unable to process purchase', {
        position: "top-center",
        autoClose: 3000,
      });
    } finally {
      setIsPurchaseDisabled(false);
    }
  };

  if (loading) {
    return (
      <div className="digital-product__placeholder loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (error === 'Event not found') {
    return (
      <div className="digital-product">
        <div className="status-container error">
          <i className="fas fa-exclamation-triangle" />
          <p>Event not found</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="digital-product">
        <div className="status-container error">
          <i className="fas fa-exclamation-circle" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!eventData) {
    return (
      <div className="digital-product">
        <div className="status-container">
          <i className="fas fa-folder-open" />
          <p>No event data available</p>
        </div>
      </div>
    );
  }

  const pageTitle = `Digital Card - ${eventData.eventName || 'Event'} | Runner Roll`;
  const pageDescription = `Create a digital card for ${eventData.runnerName}'s ${eventData.eventName} event`;

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <div className="digital-product">
        <div className="digital-product-wrapper">
          <div className="digital-product-content">
            <header className="product-header">
              <h2>Create Your Digital Card</h2>
              <p className="subtitle">Customize and preview your digital memorabilia</p>
            </header>

            <div className="preview-section">
              <h3 className="section-title">Preview</h3>
              <div className="preview-content">
                {eventData.imageUrl && selectedFields.photo && (
                  <div className="digital-product__photo-wrapper">
                    <CardPhoto
                      imageUrl={eventData.imageUrl}
                      eventName={eventData.eventName}
                      isImageLoading={isImageLoading}
                      setIsImageLoading={setIsImageLoading}
                    />
                  </div>
                )}

                <CardInfo
                  eventData={eventData}
                  selectedFields={selectedFields}
                />
              </div>
            </div>

            <div className="options-section">
              <h3 className="section-title">Customize Your Card</h3>
              <div className="checkbox-group">
                {Object.entries(selectedFields).map(([field, checked]) => (
                  <label key={field} className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={() => {
                        setSelectedFields(prev => ({
                          ...prev,
                          [field]: !prev[field]
                        }));
                      }}
                    />
                    <span className="checkbox-custom"></span>
                    <span className="label-text">
                      {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div className="purchase-section">
              <button
                className={`purchase-button ${isPurchaseDisabled ? 'disabled' : ''}`}
                onClick={handlePurchase}
                disabled={isPurchaseDisabled}
              >
                <i className="fas fa-shopping-cart"></i>
                Purchase Digital Card
              </button>
              <Link to={`/event/${eventId}`} className="cancel-link">
                Return to event
              </Link>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
}

export default DigitalProduct; 