import React, { useRef, useEffect } from 'react';
import './SavePrompt.css';

const SavePrompt = ({ isOpen, onConfirm, onCancel }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === dialogRef.current) {
      onCancel();
    }
  };

  return (
    <dialog 
      ref={dialogRef} 
      className="save-dialog" 
      onClick={handleBackdropClick}
    >
      <div className="save-content" onClick={e => e.stopPropagation()}>
        <div className="save-header">
          <h2>Save Event?</h2>
        </div>

        <div className="save-message">
          Would you like to save this event to your account?
          If not, the event will be discarded.
        </div>

        <div className="save-actions">
          <button 
            className="save-button secondary"
            onClick={onCancel}
          >
            Discard Event
          </button>
          <button 
            className="save-button primary"
            onClick={onConfirm}
          >
            Save Event
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default SavePrompt; 