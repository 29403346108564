import React from 'react';
import '../styles/ActionButtons.css';

const ActionButtons = ({ 
  eventData, 
  navigate,
  onShare,
  user
}) => { 
  return (
    <div className="action-buttons">
      <div className="action-button-wrapper">
        <button 
          className="action-button share-button"
          onClick={onShare}
          title="Share this event"
        >
          <i className="fas fa-share-alt"></i>
        </button>
      </div>
      {user && (
        <div className="action-button-wrapper template">
          <button 
            className="action-button template"
            onClick={() => navigate('/starting-line', {
              state: {
                template: {
                  eventName: eventData.eventData.eventName,
                  eventDate: eventData.eventData.eventDate,
                  eventTime: eventData.eventData.eventTime,
                  location: eventData.eventData.location,
                  imageUrl: eventData.imageUrl,
                  photoId: eventData.photoId,
                  isTemplate: true,
                  status: 'active',
                  sourceTemplateId: eventData.eventId
                }
              }
            })}
            title="Create your own event using this template"
          >
            <i className="fas fa-copy"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionButtons; 