import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import './AccountSettings.css';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { toast } from 'react-toastify';

const AccountSettings = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [, setUserData] = useState(null);
  const [role, setRole] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const getCachedUserData = () => {
    const cached = localStorage.getItem('userData');
    if (!cached) return null;
    
    const data = JSON.parse(cached);
    const lastUpdated = new Date(data.lastUpdated);
    const now = new Date();
    
    // If cache is older than 1 hour, remove it
    if (now.getTime() - lastUpdated.getTime() > 3600000) {
      localStorage.removeItem('userData');
      return null;
    }
    
    return data;
  };

  const subscriptionOptions = [
    { value: 'free', label: 'Free Tier', features: ['Basic customization', 'Digital downloads'] },
    { value: 'premium', label: 'Premium', features: ['Advanced customization', 'Unlimited downloads', 'Print ordering'] },
    { value: 'pro', label: 'Professional', features: ['All Premium features', 'Bulk creation', 'Analytics'] }
  ];

  useEffect(() => {
    const loadUserData = async () => {
      if (!user) return;

      try {
        // Try to get cached data first
        const cached = localStorage.getItem('userData');
        if (cached) {
          const data = JSON.parse(cached);
          setUserData(data);
          setRole(data.role || 'user');
          setSubscriptionType(data.subscriptionType || 'free');
          setLoading(false);
          return;
        }

        // If no cache, fetch from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const data = userDoc.exists() ? userDoc.data() : {};
        
        const updatedData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          role: data.role || 'user',
          subscriptionType: data.subscriptionType || 'free',
          lastUpdated: new Date().toISOString()
        };

        setUserData(updatedData);
        setRole(updatedData.role);
        setSubscriptionType(updatedData.subscriptionType);
        localStorage.setItem('userData', JSON.stringify(updatedData));
      } catch (error) {
        console.error('Error loading user data:', error);
        toast.error('Failed to load user data');
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [user]);

  const handleRoleChange = (e) => {
    setRole(e.target.value);
    setHasChanges(true);
  };

  const handleSubscriptionChange = (e) => {
    setSubscriptionType(e.target.value);
    setHasChanges(true);
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      
      if (!user?.uid) {
        throw new Error('No user logged in');
      }

      const updates = {
        role,
        subscriptionType,
        updatedAt: new Date().toISOString()
      };

      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, updates);

      // Update local cache
      const cachedData = getCachedUserData();
      if (cachedData) {
        localStorage.setItem('userData', JSON.stringify({
          ...cachedData,
          ...updates,
          lastUpdated: new Date().toISOString()
        }));
      }

      setHasChanges(false);
      toast.success('Account settings updated successfully!');
    } catch (error) {
      console.error('Error updating account:', error);
      toast.error('Failed to update account settings. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="loading-state">
        <i className="fas fa-spinner fa-spin"></i>
        Loading account settings...
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="account-settings-container">
      <header className="account-header">
        <h1>Account Settings</h1>
      </header>

      <div className="account-content">
        <div className="profile-section">
          <div className="profile-photo-container">
            {user?.photoURL ? (
              <img 
                src={user.photoURL} 
                alt={user.displayName || 'Profile photo'} 
                className="profile-photo"
                loading="lazy"
                crossOrigin="anonymous"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.style.display = 'none';
                  e.target.nextElementSibling.style.display = 'block';
                }}
              />
            ) : (
              <i className="fas fa-user-circle profile-icon"></i>
            )}
            <i className="fas fa-user-circle profile-icon" style={{ display: 'none' }}></i>
          </div>
          <div className="profile-info">
            <h2>{user.displayName}</h2>
            <p className="email">{user.email}</p>
            <p className="joined-date">
              Joined {new Date(user.metadata.creationTime).toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="account-details">
          <div className="detail-section">
            <h3>Account Information</h3>
            <div className="detail-grid">
              <div className="detail-item">
                <label>Name</label>
                <span>{user.displayName}</span>
              </div>
              <div className="detail-item">
                <label>Email</label>
                <span>{user.email}</span>
              </div>
              <div className="detail-item">
                <label>Account Type</label>
                <span>Google Account</span>
              </div>
              <div className="detail-item">
                <label>Last Sign In</label>
                <span>{new Date(user.metadata.lastSignInTime).toLocaleString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="profile-controls">
        <h2>Profile Settings</h2>
        
        <div className="settings-group">
          <h3>Role</h3>
          <div className="current-role">
            Current Role: <span className="highlight">{role}</span>
          </div>
          <select 
            value={role} 
            onChange={handleRoleChange}
            className="settings-select"
          >
            <option value="user">User</option>
            <option value="creator">Creator</option>
            <option value="admin">Admin</option>
          </select>
        </div>

        <div className="settings-group">
          <h3>Subscription</h3>
          <div className="current-subscription">
            Current Plan: <span className="highlight">{subscriptionType}</span>
          </div>
          {subscriptionOptions.map((option) => (
            <div key={option.value} className="subscription-option">
              <input
                type="radio"
                id={option.value}
                name="subscription"
                value={option.value}
                checked={subscriptionType === option.value}
                onChange={handleSubscriptionChange}
              />
              <label htmlFor={option.value}>
                <span className="subscription-label">{option.label}</span>
                <ul className="feature-list">
                  {option.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </label>
            </div>
          ))}
        </div>

        {hasChanges && (
          <div className="update-button-container">
            <button 
              className={`update-button ${isUpdating ? 'updating' : ''}`}
              onClick={handleUpdate}
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update Account'}
            </button>
          </div>
        )}
      </section>
    </div>
  );
};

export default AccountSettings; 