import React from 'react';
import { StatsCards } from './StatsCards';
import { EventsTable } from './EventsTable';

export const EventsSection = ({ events, stats, isLoading, onDelete, onEdit, onToggleVisibility }) => {
  return (
    <div className="events-section">
      <StatsCards stats={stats} />
      <EventsTable 
        events={events}
        onDelete={onDelete}
        onEdit={onEdit}
        onToggleVisibility={onToggleVisibility}
        isLoading={isLoading}
      />
    </div>
  );
}; 