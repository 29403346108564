import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { auth, googleProvider } from '../../firebase/config';
import { signInWithPopup, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import './Navigation.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navigation = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setIsAdmin(userSnap.data().role === 'admin');
        }
      }
    };
    
    checkAdminStatus();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleLogin = async () => {
    setIsLoggingIn(true);
    try {
      googleProvider.setCustomParameters({
        prompt: 'select_account'
      });
      
      const result = await signInWithPopup(auth, googleProvider);
      const { user } = result;
      
      // Get user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const firestoreData = userDoc.exists() ? userDoc.data() : null;
      
      // Store user data in localStorage
      localStorage.setItem('userData', JSON.stringify({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        ...firestoreData,
        lastUpdated: new Date().toISOString()
      }));

      // Check for stored eventId to claim
      const eventToClaim = localStorage.getItem('eventToClaim');
      if (eventToClaim) {
        navigate('/my-events'); // Redirect to my-events where claiming will happen
      } else {
        // Check if we're on starting line page
        const isStartingLine = window.location.pathname === '/starting-line';
        if (!isStartingLine) {
          setIsOpen(false);
        }
        // Stay on current page if we're at starting line
      }

      toast.success('Successfully logged in!');
    } catch (error) {
      console.error('Login error:', error);
      if (error.code === 'popup-closed-by-user') {
        toast.info('Login cancelled');
      } else {
        toast.error('Failed to login. Please try again.');
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleNavClick = () => {
    setIsOpen(false);
  };

  return (
    <nav ref={navRef}>
      <div className="nav-content">
        <Link to="/" className="navigation-logo">
          <img 
            src="/images/logo.webp" 
            alt="Runner Roll" 
            className="navigation-logo__image" 
            width="48" 
            height="48"
            onError={(e) => {
              e.target.onerror = null;
              e.target.style.display = 'none';
              e.target.nextElementSibling.style.display = 'flex';
            }}
          />
          <i 
            className="fas fa-running navigation-logo__fallback" 
            style={{ display: 'none' }}
            aria-hidden="true"
          ></i>
          <span className="navigation-logo__text">Runner Roll</span>
        </Link>
        <div className="nav-links">
          {user ? (
            <>
              {isAdmin && (
                <Link to="/admin" className="nav-link admin-link">
                  <i className="fas fa-shield-alt"></i>
                  <span>Admin Panel</span>
                </Link>
              )}
              <Link to="/my-events" className="nav-link">
                <i className="fas fa-trophy"></i>
                <span>My Events</span>
              </Link>
              <Link to="/my-events/archived" className="nav-link">
                <i className="fas fa-archive"></i>
                <span>Archived Events</span>
              </Link>
              <div className="profile-dropdown">
                <button 
                  onClick={() => setIsOpen(!isOpen)} 
                  className="profile-button"
                  aria-label="Profile menu"
                >
                  <div className="profile-image-container">
                    {user?.photoURL ? (
                      <>
                        <img 
                          src={user.photoURL} 
                          alt="Profile" 
                          className="profile-photo"
                          loading="lazy"
                          crossOrigin="anonymous"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.style.display = 'none';
                            e.target.nextElementSibling.style.display = 'flex';
                          }}
                        />
                        <i 
                          className="fas fa-user-circle profile-icon" 
                          style={{ display: 'none' }}
                          aria-hidden="true"
                        ></i>
                      </>
                    ) : (
                      <i className="fas fa-user-circle profile-icon"></i>
                    )}
                  </div>
                </button>

                {isOpen && (
                  <div className="dropdown-menu">
                    <div className="dropdown-header">
                      <p className="user-name">{user.displayName}</p>
                      <p className="user-email">{user.email}</p>
                    </div>
                    <Link to="/account" className="dropdown-item" onClick={handleNavClick}>
                      <i className="fas fa-cog"></i>{' '}
                      Account Settings
                    </Link>
                    <button onClick={handleSignOut} className="dropdown-item">
                      <i className="fas fa-sign-out-alt"></i>
                      Sign Out
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="/" className="nav-link visually-hidden">
                <i className="fas fa-home" aria-hidden="true"></i>
                <span>Home</span>
              </Link>
              <button 
                onClick={handleLogin} 
                className="login-button"
                disabled={isLoggingIn}
              >
                <i className="fas fa-sign-in-alt"></i>
                <span>{isLoggingIn ? 'Signing in...' : 'Login'}</span>
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation; 