import React from 'react';

export const TabButtons = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tab-buttons">
      <button 
        className={activeTab === 'events' ? 'active' : ''} 
        onClick={() => setActiveTab('events')}
      >
        Events
      </button>
      <button 
        className={activeTab === 'catalog' ? 'active' : ''} 
        onClick={() => setActiveTab('catalog')}
      >
        Catalog
      </button>
    </div>
  );
}; 