import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export const useAdminStatus = (userEmail) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      setLoading(true);
      setIsAdmin(false);

      if (!userEmail) {
        setLoading(false);
        return;
      }

      try {
        const db = getFirestore();
        const adminConfigRef = doc(db, 'adminConfig', 'emails');
        const adminConfigSnap = await getDoc(adminConfigRef);
        
        if (adminConfigSnap.exists()) {
          const { allowedEmails } = adminConfigSnap.data();
          const adminStatus = Array.isArray(allowedEmails) && allowedEmails.includes(userEmail);
          setIsAdmin(adminStatus);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, [userEmail]);

  return { isAdmin, loading };
}; 