import React from 'react';
import './TopImpact.css';

const TopImpact = () => {
  return (
    <section className="top-impact">
      <div className="impact-hero">
        <img 
          src="/images/hero.webp" 
          alt="Ambassador Community"
          onError={(e) => {
            e.target.src = '/images/hero.webp';
            e.target.onerror = null;
          }}
        />
      </div>
      <div className="impact-stats">
        <div className="stat-item">
          <h3>$30.4B</h3>
          <p>Global Running Market</p>
        </div>
        <div className="stat-item">
          <h3>50M+</h3>
          <p>Active U.S. Runners</p>
        </div>
        <div className="stat-item">
          <h3>8.9M</h3>
          <p>Annual Race Finishers</p>
        </div>
      </div>
      <div className="mission-statement">
        <p>
          We are building an elite community of tech-savvy athletes and innovators who harness data-driven insights to transform fitness journeys, inspire authentic leadership, and make revolutionary wellness solutions accessible to all.
        </p>
      </div>
    </section>
  );
};

export default TopImpact;