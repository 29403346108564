import React from 'react';
import './CardInfo.css';

const EventInfo = ({ eventData, selectedFields }) => {
  const InfoItem = ({ icon, label, value, highlight }) => (
    <div className="info-item">
      <i className={`fas ${icon}`}></i>
      <div className="info-details">
        <span className="info-label">{label}</span>
        <span className={`info-value ${highlight ? 'highlight' : ''}`}>{value}</span>
      </div>
    </div>
  );

  return (
    <div className="champion-frame info-frame">
      <div className="champion-content">
        <div className="event-info">
          {selectedFields.runnerName && (
            <InfoItem
              icon="fa-running"
              label="Runner"
              value={eventData.runnerName}
              highlight
            />
          )}
          
          <div className="info-grid">
            {selectedFields.eventName && (
              <InfoItem
                icon="fa-running"
                label="Event"
                value={eventData.eventName}
              />
            )}
            
            {selectedFields.location && (
              <InfoItem
                icon="fa-map-marker-alt"
                label="Location"
                value={eventData.location || 'Unspecified'}
              />
            )}
            
            {selectedFields.eventDate && (
              <InfoItem
                icon="fa-calendar-alt"
                label="Date"
                value={formatEventDate(eventData.eventDate)}
              />
            )}
            
            {selectedFields.eventTime && (
              <InfoItem
                icon="fa-clock"
                label="Start Time"
                value={formatEventTime(eventData.eventTime)}
              />
            )}
            
            {selectedFields.finishTime && (
              <InfoItem
                icon="fa-flag-checkered"
                label="Finish Time"
                value={formatFinishTime(eventData)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper functions from EventInfo.jsx
const formatEventDate = (dateString) => {
  const [year, month, day] = dateString.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
};

const formatEventTime = (timeString) => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12;
  return `${displayHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const formatFinishTime = (details) => {
  if (!details.finishTime || !/^\d{1,2}:\d{2}(:\d{2})?$/.test(details.finishTime)) {
    return 'Not recorded';
  }

  const parts = details.finishTime.split(':').map(Number);
  const [first, second, third] = parts;
  
  const hours = parts.length === 3 ? first : 0;
  const minutes = parts.length === 3 ? second : first;
  const seconds = parts.length === 3 ? third : second;
  
  const timeParts = [];
  
  if (hours > 0) timeParts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  if (minutes > 0) timeParts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  if (seconds > 0) timeParts.push(`${seconds} ${seconds === 1 ? 'second' : 'seconds'}`);
  
  return timeParts.join(' ') || '0 seconds';
};

export default EventInfo; 