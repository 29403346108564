import React from 'react';
import '../styles/Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner" />
      <div className="loading-text">Loading event details...</div>
      <div className="loading-skeleton" style={{ width: '60%' }} />
      <div className="loading-skeleton" style={{ width: '40%' }} />
      <div className="loading-skeleton" style={{ width: '80%' }} />
    </div>
  );
};

export default Loading; 