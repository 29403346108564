import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  deleteDoc,
  doc as firestoreDoc,
  getDoc
} from 'firebase/firestore';

const EventCard = ({ event, onDelete, onView, setDeleteConfirm }) => {

  return (
    <div key={event.id} className="event-card">
      <div className="event-image">
        {event.imageUrl ? (
          <img 
            src={event.imageUrl} 
            alt={`${event.runnerName}'s event`}
            onError={(e) => {
              console.log('Image failed to load:', event.imageUrl);
              e.target.onerror = null;
              e.target.src = '/placeholder-event.png';
            }}
          />
        ) : (
          console.log('No imageUrl for event:', event.id, event),
          <div className="placeholder-image">
            <i className="fas fa-running"></i>
          </div>
        )}
        <button 
          className="delete-button"
          onClick={() => setDeleteConfirm(event.id)}
          aria-label="Delete event"
        >
          <i className="fas fa-trash-alt"></i>
        </button>
      </div>
      <div className="event-info">
        <h3>{event.eventName}</h3>
        <p>{event.runnerName}</p>
        <p className="event-date">
          {new Date(event.eventDate).toLocaleDateString()}
        </p>
      </div>
      <button 
        className="view-event-button"
        onClick={() => onView(`/event/${event.id}`)}
      >
        View Event
      </button>
    </div>
  );
};

function MyArchivedEvents() {
  const [archivedEvents, setArchivedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
  }, [user, navigate]);

  useEffect(() => {
    const fetchArchivedEvents = async () => {
      if (!user) return;

      try {
        const eventsRef = collection(db, 'events');
        const q = query(
          eventsRef,
          where('userId', '==', user.uid),
          where('status', '==', 'archived')
        );
        
        const querySnapshot = await getDocs(q);
        const eventsData = [];
        
        for (const docSnapshot of querySnapshot.docs) {
          const event = docSnapshot.data();
          
          let imageUrl = null;
          if (event.photoId) {
            const photoDoc = await getDoc(firestoreDoc(db, 'photos', event.photoId));
            imageUrl = photoDoc.exists() ? photoDoc.data().imageUrl : null;
          }
          
          const eventWithPhoto = {
            id: docSnapshot.id,
            ...event,
            imageUrl
          };
          
          eventsData.push(eventWithPhoto);
        }
        
        setArchivedEvents(eventsData);
      } catch (error) {
        console.error('Error fetching archived events:', error);
        setError('Failed to load archived events');
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchArchivedEvents();
    }
  }, [user]);

  const handleDelete = async (eventId) => {
    setIsDeleting(true);
    try {
      await deleteDoc(firestoreDoc(db, 'events', eventId));
      
      const photoQuery = query(
        collection(db, 'photos'),
        where('eventId', '==', eventId)
      );
      const photoSnapshot = await getDocs(photoQuery);
      
      for (const doc of photoSnapshot.docs) {
        await deleteDoc(doc.ref);
      }

      setArchivedEvents(archivedEvents.filter(event => event.id !== eventId));
      setDeleteConfirm(null);
    } catch (err) {
      console.error('Error deleting archived event:', err);
      setError('Failed to delete event. Please try again.');
    } finally {
      setIsDeleting(false);
    }
  };

  if (!user) return null;
  if (loading) return <div className="loading">Loading archived events...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="user-events-container">
      <header className="user-events-header">
        <h1>Archived Events</h1>
      </header>

      <div className="events-grid">
        {archivedEvents.length === 0 ? (
          <div className="no-events">
            <p>You don't have any archived events.</p>
          </div>
        ) : (
          archivedEvents.map(event => (
            <EventCard 
              key={event.id} 
              event={event} 
              onDelete={handleDelete} 
              onView={navigate}
              setDeleteConfirm={setDeleteConfirm}
            />
          ))
        )}
      </div>

      {deleteConfirm && (
        <div className="delete-modal">
          <div className="delete-modal-content">
            <h2>Delete Archived Event</h2>
            <p>Are you sure you want to delete this archived event? This action cannot be undone.</p>
            <div className="delete-modal-buttons">
              <button 
                className="cancel-button"
                onClick={() => setDeleteConfirm(null)}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button 
                className="confirm-delete-button"
                onClick={() => handleDelete(deleteConfirm)}
                disabled={isDeleting}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyArchivedEvents; 