import React from 'react';

const TemplateBanner = ({ onUseTemplate }) => (
  <div className="template-banner">
    <div className="template-content">
      <i className="fas fa-copy template-icon"></i>
      <span className="template-message">Join the Starting Line - It's Free!</span>
      <button 
        className="template-button"
        onClick={onUseTemplate}
        title="Create your own event using this template"
      >
        Use Template
      </button>
    </div>
  </div>
);

export default TemplateBanner; 