import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, addDoc } from 'firebase/firestore';
import './EventDetails.css';
import { useAuth } from '../../context/AuthContext';

const EventDetails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const runnerName = searchParams.get('runner') || '';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const now = new Date();
  const defaultDate = new Date(now.setDate(now.getDate() + 7)).toISOString().split('T')[0];
  const defaultTime = new Date(Math.ceil(now.getTime() / 3600000) * 3600000).toTimeString().slice(0, 5);

  const [eventData, setEventData] = useState({
    eventName: `${runnerName}'s Event`,
    eventDate: defaultDate,
    eventTime: defaultTime,
    location: '',
    finishTime: ''
  });

  const handleChange = (e) => {
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      // Create event document with optional userId
      const eventDoc = {
        runnerName,
        ...eventData,
        createdAt: new Date().toISOString(),
        status: 'pending'
      };

      // Only add userId if user is authenticated
      if (user) {
        eventDoc.userId = user.uid;
      }

      const docRef = await addDoc(collection(db, 'events'), eventDoc);

      console.log('Document written with ID: ', docRef.id);
      navigate('/photo-upload', { 
        state: { 
          eventId: docRef.id,
          runnerName,
          eventData 
        } 
      });
    } catch (err) {
      console.error('Error adding document: ', err);
      setError('Failed to save event details. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReview = () => {
    setShowReview(true);
  };

  const [showReview, setShowReview] = useState(false);
  const dialogRef = useRef(null);

  useEffect(() => {
    if (showReview) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [showReview]);

  const handleBackdropClick = (e) => {
    if (e.target === dialogRef.current) {
      setShowReview(false);
    }
  };

  return (
    <div className="event-details-container">
      <header className="event-header">
        <h1>
          <span className="runner-name">{runnerName}'s</span>
          <span className="header-title">Event Details</span>
        </h1>
        <p className="header-subtitle">Tell us about the race achievement</p>
        {!user && (
          <div className="auth-banner">
            <i className="fas fa-user-circle"></i>
            <p className="auth-note">
              Sign in to save this event to your account
            </p>
            <Link to="/signin" className="auth-link">Sign In</Link>
          </div>
        )}
      </header>

      <div className="event-form-container">
        <form onSubmit={handleSubmit} className="event-form">
          <div className="form-section">
            <h2 className="section-title">Basic Information</h2>
            <div className="form-group">
              <label htmlFor="eventName">
                <i className="fas fa-trophy"></i>{' '}
                Event Name
              </label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                value={eventData.eventName}
                onChange={handleChange}
                placeholder="City Spring 5K"
                required
              />
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="eventDate">
                  <i className="fas fa-calendar"></i>{' '}
                  Event Date
                </label>
                <input
                  type="date"
                  id="eventDate"
                  name="eventDate"
                  value={eventData.eventDate}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="eventTime">
                  <i className="fas fa-clock"></i>{' '}
                  Start Time
                </label>
                <input
                  type="time"
                  id="eventTime"
                  name="eventTime"
                  value={eventData.eventTime}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2 className="section-title">Additional Details</h2>
            <div className="form-group">
              <label htmlFor="location">
                <i className="fas fa-map-marker-alt"></i>{' '}
                Location
                <span className="optional-text">(optional)</span>
              </label>
              <input
                type="text"
                id="location"
                name="location"
                value={eventData.location}
                onChange={handleChange}
                placeholder="Central Park, New York"
              />
              <small className="helper-text">
                <i className="fas fa-info-circle"></i>
                Add the race location to help supporters find your event. You can update this later.
              </small>
            </div>

            <div className="form-group">
              <label htmlFor="finishTime">
                <i className="fas fa-stopwatch"></i>{' '}
                Finish Time
                <span className="optional-text">(optional)</span>
              </label>
              <input
                type="text"
                id="finishTime"
                name="finishTime"
                value={eventData.finishTime}
                onChange={handleChange}
                placeholder="25:30"
              />
              <small className="helper-text">
                <i className="fas fa-info-circle"></i>
                Don't worry if you don't know the finish time yet - you can celebrate and add it after the race!
              </small>
            </div>
          </div>

          {error && (
            <div className="error-message">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}

          <div className="button-group">
            <button 
              type="button" 
              className="review-button" 
              onClick={handleReview}
              disabled={isSubmitting}
            >
              <i className="fas fa-eye"></i>
              Review Information
            </button>
            <button 
              type="submit" 
              className="next-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i>
                  Saving...
                </>
              ) : (
                <>
                  <i className="fas fa-arrow-right"></i>
                  Continue
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      <dialog 
        ref={dialogRef} 
        className="review-dialog" 
        onClose={() => setShowReview(false)}
        onClick={handleBackdropClick}
      >
        <div className="review-content">
          <div className="review-header">
            <h2>Review Information</h2>
            <button 
              className="close-button" 
              onClick={() => setShowReview(false)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>

          <div className="review-info">
            <div className="review-section">
              <h3>
                <i className="fas fa-running"></i>
                Runner Information
              </h3>
              <p><strong>Runner Name:</strong> {runnerName}</p>
            </div>
            
            <div className="review-section">
              <h3>
                <i className="fas fa-trophy"></i>
                Event Information
              </h3>
              <p><strong>Event Name:</strong> {eventData.eventName}</p>
              <p><strong>Date:</strong> {eventData.eventDate}</p>
              <p><strong>Start Time:</strong> {eventData.eventTime}</p>
              <p><strong>Location:</strong> {eventData.location || 'Not specified'}</p>
              <p><strong>Finish Time:</strong> {eventData.finishTime || 'Not specified'}</p>
            </div>
          </div>

          <div className="review-footer">
            <button 
              onClick={() => setShowReview(false)} 
              className="close-button"
            >
              <i className="fas fa-check"></i>
              Looks Good
            </button>
          </div>
        </div>
      </dialog>
    </div>
  );
};

export default EventDetails;