export const FOOTER_LINKS = {
  legal: [
    {
      label: 'Privacy',
      path: 'https://www.metamotionhq.com/privacy-policy'
    },
    {
      label: 'Terms',
      path: 'https://www.metamotionhq.com/terms'
    }
  ],
  navigation: [
    {
      label: 'Become an Ambassador',
      path: '/ambassadors'
    },
    // {
    //   label: 'Contact',
    //   path: '/contact'
    // }
  ]
}; 