import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import TopImpact from './TopImpact';
import AmbassadorCTA from './AmbassadorCTA';
import './Ambassadors.css';

const Ambassadors = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ambassadors-container">
      <Helmet>
        <title>Our Ambassadors | Runner Roll</title>
        <meta name="description" content="Meet our amazing ambassadors who represent our brand and community." />
      </Helmet>

      <section className="ambassadors-hero">
        <h1>Become an Ambassador</h1>
        <p className="hero-subtitle">Where Passion Meets Purpose</p>
      </section>

      <TopImpact />
      <AmbassadorCTA />
    </div>
  );
};

export default Ambassadors; 