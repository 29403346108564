import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, getDocs, query, where, orderBy, updateDoc, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import './UserEvents.css';
import { toast } from 'react-hot-toast';
import fallbackEventImage from '../../assets/images/default-event.webp';

const getCountdown = (eventDate, eventTime = '00:00') => {
  const now = new Date();

  // Parse date and time
  const [year, month, day] = eventDate.split('-').map(Number);
  const [hours, minutes] = eventTime.split(':').map(Number);
  const event = new Date(year, month - 1, day, hours, minutes);

  const diff = event - now;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  if (days > 0) {
    return `${days} day${days !== 1 ? 's' : ''} to go`;
  } else if (remainingHours > 0) {
    return `${remainingHours} hour${remainingHours !== 1 ? 's' : ''} to go`;
  } else if (diff > 0) {
    return 'Starting soon';
  } else {
    return 'Event completed';
  }
};

const UserEvents = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [archiveConfirm, setArchiveConfirm] = useState(null);
  const [isArchiving, setIsArchiving] = useState(false);

  const fetchUserEvents = useCallback(async () => {
    if (!user?.uid) {
      setError('Please log in to view your events');
      setLoading(false);
      return;
    }
  
    try {
      const eventsQuery = query(
        collection(db, 'events'),
        where('userId', '==', user.uid),
        where('status', '!=', 'archived'),
        orderBy('eventDate', 'desc')
      );
      
      const querySnapshot = await getDocs(eventsQuery);
      
      const eventsData = [];
      
      for (const docSnapshot of querySnapshot.docs) {
        const event = docSnapshot.data();
        
        // Skip photo fetching if there's an error, use fallback
        let imageUrl = fallbackEventImage;
        try {
          if (event.photoId) {
            const photoDoc = await getDoc(doc(db, 'photos', event.photoId));
            if (photoDoc.exists()) {
              imageUrl = photoDoc.data().imageUrl;
            }
          }
        } catch (photoError) {
          console.warn('Error fetching photo, using fallback:', photoError);
          // Continue with fallback image
        }
        
        eventsData.push({
          id: docSnapshot.id,
          ...event,
          imageUrl
        });
      }
      
      setEvents(eventsData);
      setError(null);
    } catch (err) {
      console.error('Error details:', {
        code: err.code,
        message: err.message,
        userId: user?.uid,
        stack: err.stack
      });
      setError('Failed to load events. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserEvents();
  }, [user, fetchUserEvents]);

  useEffect(() => {
    const claimEvent = async () => {
      const eventToClaim = localStorage.getItem('eventToClaim');

      if (eventToClaim && user) {
        try {
          await updateDoc(doc(db, 'events', eventToClaim), {
            userId: user.uid
          });

          toast.success('Event claimed successfully!');
          localStorage.removeItem('eventToClaim');

          await fetchUserEvents();
        } catch (error) {
          console.error('Error claiming event:', error);
          toast.error('Failed to claim event');
        }
      }
    };

    claimEvent();
  }, [user, fetchUserEvents]);

  const handleArchive = async (eventId) => {
    setIsArchiving(true);
    try {
      // Update the event status to archived
      const eventRef = doc(db, 'events', eventId);
      await updateDoc(eventRef, {
        status: 'archived',
        archivedAt: new Date().toISOString()
      });

      // Update local state
      setEvents(events.filter(event => event.id !== eventId));
      setArchiveConfirm(null);
    } catch (err) {
      console.error('Error archiving event:', err);
      setError('Failed to archive event. Please try again.');
    } finally {
      setIsArchiving(false);
    }
  };

  if (loading) return <div className="loading">Loading your events...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="user-events-container">
      <header className="user-events-header">
        <h1>My Events</h1>
        <button
          className="new-event-button"
          onClick={() => navigate('/starting-line')}
        >
          Create New Event
        </button>
      </header>

      <div className="events-grid">
        {events.length === 0 ? (
          <div className="no-events">
            <p>You haven't created any events yet.</p>
            <button
              className="create-first-button"
              onClick={() => navigate('/starting-line')}
            >
              Create Your First Event
            </button>
          </div>
        ) : (
          events.map(event => (
            <div key={event.id} className="event-card">
              <div className="event-image">
                <img
                  src={event.imageUrl}
                  alt={`${event.runnerName}'s event`}
                  onError={(e) => {
                    e.target.src = fallbackEventImage;
                    e.target.onerror = null; // Prevent infinite loop
                  }}
                  loading="lazy"
                />
                <button
                  className="archive-button"
                  onClick={() => setArchiveConfirm(event.id)}
                  aria-label="Archive event"
                >
                  <i className="fas fa-archive"></i>
                </button>
              </div>
              <div className="event-info">
                <h3>{event.eventName}</h3>
                <p>{event.runnerName}</p>
                <p className="event-timing-display">
                  {event.eventDate ? (
                    new Date() < (() => {
                      const [year, month, day] = event.eventDate.split('-').map(Number);
                      const [hours, minutes] = (event.eventTime || '00:00').split(':').map(Number);
                      return new Date(year, month - 1, day, hours, minutes);
                    })() ? (
                      <>
                        <i className="fas fa-hourglass-half"></i>
                        {getCountdown(event.eventDate, event.eventTime)}
                      </>
                    ) : (
                      <>
                        <i className="fas fa-flag-checkered"></i>
                        Event Completed
                      </>
                    )
                  ) : (
                    <>
                      <i className="fas fa-calendar"></i>
                      Date to be announced
                    </>
                  )}
                </p>
              </div>
              <button
                className="view-event-button"
                onClick={() => navigate(`/event/${event.id}`)}
              >
                View Event
              </button>
            </div>
          ))
        )}
      </div>

      {archiveConfirm && (
        <div className="archive-modal">
          <div className="archive-modal-content">
            <h2>Archive Event</h2>
            <p>Are you sure you want to archive this event? You can access it later in your archived events.</p>
            <div className="archive-modal-buttons">
              <button
                className="cancel-button"
                onClick={() => setArchiveConfirm(null)}
                disabled={isArchiving}
              >
                Cancel
              </button>
              <button
                className="confirm-archive-button"
                onClick={() => handleArchive(archiveConfirm)}
                disabled={isArchiving}
              >
                {isArchiving ? 'Archiving...' : 'Archive'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserEvents;