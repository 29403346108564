import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

export const useEvents = () => {
  const [events, setEvents] = useState([]);
  const [stats, setStats] = useState({ total: 0, active: 0, archived: 0, completed: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const fetchEvents = useCallback(async () => {
    try {
      const eventsQuery = query(
        collection(db, 'events'),
        orderBy('createdAt', 'desc')
      );
      const snapshot = await getDocs(eventsQuery);
      const eventsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setEvents(eventsData);
      calculateStats(eventsData);
    } catch (error) {
      if (error.code === 'permission-denied') {
        setError('You do not have permission to view events. Please check your login status.');
        toast.error('Access denied. Please check your permissions.');
      } else {
        setError('Failed to fetch events');
      }
      console.error('Error fetching events:', error);
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const calculateStats = (eventsData) => {
    const stats = eventsData.reduce((acc, event) => ({
      total: acc.total + 1,
      active: acc.active + (event.status === 'active' ? 1 : 0),
      archived: acc.archived + (event.status === 'archived' ? 1 : 0),
      completed: acc.completed + (event.status === 'completed' ? 1 : 0)
    }), { total: 0, active: 0, archived: 0, completed: 0 });
    
    setStats(stats);
  };

  const handleVisibilityToggle = async (eventId) => {
    try {
      // Find the event in current state
      const event = events.find(e => e.id === eventId);
      if (!event) {
        throw new Error('Event not found');
      }

      const eventRef = doc(db, 'events', eventId);
      const newIsPublic = !event.isPublic;
      
      await updateDoc(eventRef, { isPublic: newIsPublic });
      
      // Update local state
      setEvents(events.map(e => 
        e.id === eventId ? { ...e, isPublic: newIsPublic } : e
      ));
      
      toast.success(`Event "${event.eventName}" is now ${newIsPublic ? 'public' : 'private'}`);
    } catch (err) {
      console.error('Error updating event visibility:', err);
      toast.error('Failed to update event visibility');
    }
  };

  const handleDelete = async (eventId) => {
    try {
      const eventRef = doc(db, 'events', eventId);
      await deleteDoc(eventRef);
      
      // Update local state
      setEvents(events.filter(event => event.id !== eventId));
      
      // Show success message
      setSuccessMessage('Event deleted successfully');
      setTimeout(() => setSuccessMessage(null), 5000);
      
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Failed to delete event. Please try again.');
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleEdit = (eventId) => {
    navigate(`/event/${eventId}/edit`);
  };

  const handleShare = async (template) => {
    const shareUrl = `${window.location.origin}/#/event/${template.id}`;
    const shareTitle = `${template.eventName} 🎉`;
    const shareText = `Join the ${template.eventName}! 🏃‍♀️

📍 Event Details: ${template.eventName}
📅 ${template.eventDate || 'Date TBD'}
📍 ${template.location || 'Location TBD'}
🔗 Event Link: ${shareUrl}

Tap the link to register, see event info, and get all the details! Looking forward to seeing you there! ✨`;

    try {
      if (navigator.share) {
        await navigator.share({
          title: shareTitle,
          text: shareText,
          url: shareUrl,
        });
        toast.success('Shared successfully!');
      } else {
        // Copy formatted text to clipboard
        await navigator.clipboard.writeText(shareText);
        toast.success('Event details copied to clipboard!', {
          description: 'Ready to paste and share!'
        });
      }
    } catch (error) {
      console.error('Error sharing:', error);
      toast.error('Failed to share template');
    }
  };

  return {
    events,
    stats,
    isLoading,
    error,
    successMessage,
    handleEdit,
    handleDelete,
    handleVisibilityToggle,
    handleShare,
  };
}; 